

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Browser from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Browser.bs.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Md from "react-icons/md";
import * as Si from "react-icons/si";
import * as ColiswebApi__Env from "../src/ColiswebApi__Env.bs.js";
import * as PlaygroundRouter from "./PlaygroundRouter.bs.js";
import * as PlaygroundLocales from "./PlaygroundLocales.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Layout from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Layout.bs.js";
import * as PlaygroundComponents from "./PlaygroundComponents.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";
import * as ApiComponents__ErrorPlaceholder from "../src/components/ApiComponents__ErrorPlaceholder.bs.js";
import * as ApiComponents__AccessRestrictedByRoles from "../src/components/ApiComponents__AccessRestrictedByRoles.bs.js";

var links = [
  {
    TAG: "Single",
    _0: {
      TAG: "App",
      _0: {
        route: "Home",
        icon: Caml_option.some(JsxRuntime.jsx(Md.MdHome, {
                  size: 30
                })),
        label: "Home"
      }
    }
  },
  {
    TAG: "Single",
    _0: {
      TAG: "App",
      _0: {
        route: {
          TAG: "Components",
          _0: "List"
        },
        icon: Caml_option.some(JsxRuntime.jsx(Si.SiDatabricks, {
                  size: 30
                })),
        label: "Components"
      }
    }
  }
];

function PlaygroundApp$App(props) {
  return JsxRuntime.jsx(PlaygroundRouter.Provider.make, {
              children: (function (currentRoute) {
                  var tmp;
                  if (typeof currentRoute !== "object") {
                    tmp = currentRoute === "Home" ? "Home" : "NotFound";
                  } else {
                    var component = currentRoute._0;
                    tmp = typeof component !== "object" ? JsxRuntime.jsx(PlaygroundComponents.List.make, {}) : JsxRuntime.jsx(PlaygroundComponents.Details.make, {
                            component: component._0
                          });
                  }
                  return JsxRuntime.jsxs(Toolkit__Ui_Layout.App.make, {
                              children: [
                                JsxRuntime.jsx(PlaygroundRouter.Breadcrumb.make, {}),
                                JsxRuntime.jsx("div", {
                                      children: tmp,
                                      className: "p-4"
                                    })
                              ],
                              sideNavRender: (function (param) {
                                  var openMenu = param.openMenu;
                                  var isNavOpen = param.isNavOpen;
                                  var onLinkClick = param.onLinkClick;
                                  return JsxRuntime.jsx("div", {
                                              children: Belt_Array.mapWithIndex(links, (function (i, link) {
                                                      var tmp;
                                                      tmp = link.TAG === "Single" ? JsxRuntime.jsx(PlaygroundRouter.SingleLink.make, {
                                                              link: link._0,
                                                              isNavOpen: isNavOpen,
                                                              onLinkClick: onLinkClick
                                                            }) : JsxRuntime.jsx(PlaygroundRouter.GroupedLinks.make, {
                                                              groupInfo: link._0,
                                                              links: link._1,
                                                              isNavOpen: isNavOpen,
                                                              onLinkClick: onLinkClick,
                                                              openMenu: openMenu
                                                            });
                                                      return JsxRuntime.jsx(React.Fragment, {
                                                                  children: JsxRuntime.jsx("div", {
                                                                        children: tmp,
                                                                        className: "my-3"
                                                                      })
                                                                }, "nav-item" + String(i));
                                                    }))
                                            });
                                }),
                              onLogoClick: (function (param) {
                                  Curry._1(PlaygroundRouter.redirect, "Home");
                                }),
                              logoSrc: import.meta.env.BASE_URL + "assets/logo.svg"
                            });
                })
            });
}

var App = {
  make: PlaygroundApp$App
};

function PlaygroundApp(props) {
  return JsxRuntime.jsx(PlaygroundLocales.Provider.make, {
              children: JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                    fallbackRender: (function (param) {
                        var error = param.error;
                        if (typeof error === "object" && error.NAME === "custom" && error.VAL === "unAuthorizedError") {
                          Browser.$$Location.replace(Browser.$$Location.$$location, ColiswebApi__Env.boLoginUrl + "?redirect_to=" + Browser.$$Location.pathname(Browser.$$Location.$$location));
                          return null;
                        }
                        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                    children: [
                                      JsxRuntime.jsx(Toolkit__Ui_Layout.Container.make, {
                                            children: JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.Default.make, {
                                                  error: error
                                                })
                                          }),
                                      JsxRuntime.jsx(Toolkit__Ui_Layout.Footer.make, {})
                                    ]
                                  });
                      }),
                    children: JsxRuntime.jsx(ApiComponents__AccessRestrictedByRoles.make, {
                          allowedRoles: [
                            "admin",
                            "techAdmin"
                          ],
                          children: JsxRuntime.jsx(PlaygroundApp$App, {})
                        })
                  })
            });
}

var make = PlaygroundApp;

var $$default = PlaygroundApp;

export {
  links ,
  App ,
  make ,
  $$default as default,
}
/* links Not a pure module */
