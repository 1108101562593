

import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__ParcelScanContextTag from "../../src/components/parcel/scan-context-tag/ApiComponents__ParcelScanContextTag.bs.js";

var allEnums = [
  "shuttle",
  "routePreparation",
  "inventory",
  "loading",
  "carrierReturn",
  "returnToOwner",
  "damage",
  "thrown",
  "carrierPickup",
  "carrierShipping",
  "returnToOwner",
  "receivedByOwner",
  "lost",
  "technicalCleaning"
];

function Playground_ParcelScanContextTag(props) {
  return JsxRuntime.jsx("div", {
              children: Belt_Array.map(allEnums, (function (scanContext) {
                      return JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("p", {
                                          children: scanContext,
                                          className: "text-xs text-neutral-600 font-medium"
                                        }),
                                    JsxRuntime.jsx(ApiComponents__ParcelScanContextTag.make, {
                                          scanContext: scanContext
                                        })
                                  ]
                                }, scanContext);
                    })),
              className: "flex flex-row flex-wrap gap-6 bg-white p-4"
            });
}

var make = Playground_ParcelScanContextTag;

export {
  allEnums ,
  make ,
}
/* react/jsx-runtime Not a pure module */
