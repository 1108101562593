

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__AutocompleteStores from "../../src/components/autocompletes/ApiComponents__AutocompleteStores.bs.js";
import * as ApiComponents__AutocompleteAddress from "../../src/components/autocompletes/address-autocomplete/ApiComponents__AutocompleteAddress.bs.js";

function Playground_Autocomplete(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Address example"
                            }),
                        JsxRuntime.jsx(ApiComponents__AutocompleteAddress.make, {
                              onSelect: (function (prim) {
                                  console.log(prim);
                                }),
                              onCustomAddressValidation: (function (address, disclosure) {
                                  console.log(address);
                                  Curry._1(disclosure.hide, undefined);
                                }),
                              placeholder: "Placeholder",
                              shouldGeocodeLocation: false
                            })
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Store example"
                            }),
                        JsxRuntime.jsx(ApiComponents__AutocompleteStores.make, {
                              onSelect: (function (prim) {
                                  console.log(prim);
                                }),
                              clientId: "100"
                            })
                      ]
                    })
              ],
              className: "flex flex-col gap-6"
            });
}

var make = Playground_Autocomplete;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
