

import * as HighlightJs from "highlight.js";
import * as JsxRuntime from "react/jsx-runtime";
import RescriptHighlight from "../../../playground/utils/rescript-highlight";

var HighlightJs$1 = {};

var rescriptModule = RescriptHighlight;

HighlightJs.default.registerLanguage("rescript", rescriptModule);

function Playground_CodeBlock(props) {
  var code = props.code;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("pre", {
                      children: JsxRuntime.jsx("code", {
                            dangerouslySetInnerHTML: {
                              __html: HighlightJs.default.highlight(code, {
                                    language: "rescript"
                                  }).value
                            }
                          }),
                      className: "p-4"
                    }),
                JsxRuntime.jsx("button", {
                      children: "Copy",
                      className: "absolute right-0 top-0 bg-white rounded-tl rounded-br text-sm px-2 border-b border-l hover:bg-neutral-200",
                      onClick: (function (param) {
                          navigator.clipboard.writeText(code);
                        })
                    })
              ],
              className: "Code border rounded-lg mb-6 relative"
            });
}

var make = Playground_CodeBlock;

export {
  HighlightJs$1 as HighlightJs,
  rescriptModule ,
  make ,
}
/* rescriptModule Not a pure module */
