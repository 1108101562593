

import * as Cx from "../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Js_promise2 from "../../../../node_modules/rescript/lib/es6/js_promise2.js";
import * as Restorative from "../../../../node_modules/@dck/restorative/lib/es6_global/src/Restorative.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Ui_Alert from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Alert.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Spinner from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Spinner.bs.js";

function loadScript(url, id) {
  return new Promise((function (resolve, param) {
                var script = document.createElement("script");
                script.src = url;
                script.id = id;
                script.onload = (function (param) {
                    resolve();
                  });
                script.async = true;
                document.head.append(script);
              }));
}

function loadLink(url) {
  return new Promise((function (resolve, param) {
                var script = document.createElement("link");
                script.href = url;
                script.rel = "stylesheet";
                script.onload = (function (param) {
                    resolve();
                  });
                script.async = true;
                document.head.append(script);
              }));
}

var store = Restorative.createStore("NotAsked", (function (param, action) {
        return action._0;
      }));

function ApiComponents__HereMap(props) {
  var __className = props.className;
  var onLoad = props.onLoad;
  var mapOptions = props.mapOptions;
  var className = __className !== undefined ? __className : "";
  var scriptLoadingState = Curry._2(store.useStore, undefined, undefined);
  var mapInit = React.useRef(undefined);
  var mapDomRef = React.useRef(null);
  React.useEffect((function (param) {
          var match = mapDomRef.current;
          var match$1 = mapInit.current;
          if (!(match == null) && match$1 === undefined) {
            if (typeof scriptLoadingState !== "object") {
              scriptLoadingState === "NotAsked";
            } else if (scriptLoadingState._0.TAG === "Ok") {
              var platform = new H.service.Platform({
                    apikey: ColiswebApi__Env.hereKey
                  });
              var defaultLayers = platform.createDefaultLayers({
                    pois: true
                  });
              var mapInstance = new H.Map(match, defaultLayers.vector.normal.map, {
                    zoom: mapOptions.zoom,
                    center: mapOptions.center,
                    padding: mapOptions.padding
                  });
              mapInit.current = Caml_option.some(mapInstance);
              new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance));
              var mapUI = new H.ui.UI.createDefault(mapInstance, defaultLayers);
              Belt_Option.forEach(onLoad, (function (fn) {
                      Curry._1(fn, {
                            mapInstance: mapInstance,
                            ui: mapUI,
                            platform: platform
                          });
                    }));
              window.addEventListener("resize", (function (param) {
                      mapInstance.getViewPort().resize();
                    }));
            }
            
          }
          
        }), [
        mapDomRef.current,
        scriptLoadingState
      ]);
  React.useEffect((function (param) {
          var loadScripts = async function (param) {
            loadLink("https://js.api.here.com/v3/3.1/mapsjs-ui.css");
            await loadScript("https://js.api.here.com/v3/3.1/mapsjs-core.js", "cw-here-core");
            await loadScript("https://js.api.here.com/v3/3.1/mapsjs-service.js", "cw-here-service");
            await loadScript("https://js.api.here.com/v3/3.1/mapsjs-ui.js", "cw-here-ui");
            await loadScript("https://js.api.here.com/v3/3.1/mapsjs-mapevents.js", "cw-here-mapevents");
            return await loadScript("https://js.api.here.com/v3/3.1/mapsjs-clustering.js", "cw-here-clustering");
          };
          if (typeof scriptLoadingState !== "object" && scriptLoadingState === "NotAsked") {
            Js_promise2.$$catch(Js_promise2.then(loadScripts(), (function (param) {
                        Curry._1(store.dispatch, {
                              TAG: "UpdateState",
                              _0: {
                                TAG: "Done",
                                _0: {
                                  TAG: "Ok",
                                  _0: undefined
                                }
                              }
                            });
                        return Promise.resolve();
                      })), (function (param) {
                    Curry._1(store.dispatch, {
                          TAG: "UpdateState",
                          _0: {
                            TAG: "Done",
                            _0: {
                              TAG: "Error",
                              _0: undefined
                            }
                          }
                        });
                    return Promise.resolve();
                  }));
          }
          
        }), [scriptLoadingState]);
  var tmp;
  tmp = typeof scriptLoadingState !== "object" ? JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}) : (
      scriptLoadingState._0.TAG === "Ok" ? JsxRuntime.jsx("div", {
              ref: Caml_option.some(mapDomRef),
              className: "h-full w-full",
              id: "map"
            }) : JsxRuntime.jsx(Toolkit__Ui_Alert.make, {
              title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_95266c4c",
                    defaultMessage: "Une erreur est survenue lors du chargement de la carte, veuillez actualiser la page."
                  }),
              status: "warning"
            })
    );
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: Cx.cx([
                    "h-[300px] w-full",
                    className
                  ]),
              id: props.id
            });
}

var make = ApiComponents__HereMap;

export {
  loadScript ,
  loadLink ,
  store ,
  make ,
}
/* store Not a pure module */
