

import * as Ky from "ky";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";

var kyInstance = Ky.default.create({
      prefixUrl: ColiswebApi__Env.v6ApiUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function t_encode(v) {
  switch (v) {
    case "waiting" :
        return "waiting";
    case "paid" :
        return "paid";
    case "failed" :
        return "failed";
    case "canceled" :
        return "canceled";
    case "retracted" :
        return "retracted";
    case "refunded" :
        return "refunded";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("waiting" === v) {
          return {
                  TAG: "Ok",
                  _0: "waiting"
                };
        } else if ("paid" === v) {
          return {
                  TAG: "Ok",
                  _0: "paid"
                };
        } else if ("failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "failed"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else if ("retracted" === v) {
          return {
                  TAG: "Ok",
                  _0: "retracted"
                };
        } else if ("refunded" === v) {
          return {
                  TAG: "Ok",
                  _0: "refunded"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var allStatus = [
  "waiting",
  "paid",
  "failed",
  "canceled",
  "retracted",
  "refunded"
];

var waiting = {
  id: "_439149be",
  defaultMessage: "En attente"
};

var paid = {
  id: "_a2828619",
  defaultMessage: "Payé"
};

var failed = {
  id: "_b4f68251",
  defaultMessage: "Échoué"
};

var canceled = {
  id: "_eb3c5569",
  defaultMessage: "Annulé"
};

var retracted = {
  id: "_b6c8ce3d",
  defaultMessage: "Rétracté"
};

var refunded = {
  id: "_498c9f60",
  defaultMessage: "Remboursé"
};

function humanize(intl, t) {
  var message;
  switch (t) {
    case "waiting" :
        message = waiting;
        break;
    case "paid" :
        message = paid;
        break;
    case "failed" :
        message = failed;
        break;
    case "canceled" :
        message = canceled;
        break;
    case "retracted" :
        message = retracted;
        break;
    case "refunded" :
        message = refunded;
        break;
    
  }
  return intl.formatMessage(message);
}

var TransactionStatus = {
  t_encode: t_encode,
  t_decode: t_decode,
  allStatus: allStatus,
  waiting: waiting,
  paid: paid,
  failed: failed,
  canceled: canceled,
  retracted: retracted,
  refunded: refunded,
  humanize: humanize
};

var Shared = {
  TransactionStatus: TransactionStatus
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/online-payment/public/axcepta/deliveries"
    });

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "additionalInformation",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.additionalInformation)
                  ]
                ]));
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var additionalInformation = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "additionalInformation"), null));
          if (additionalInformation.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      line1: line1._0,
                      city: city._0,
                      postalCode: postalCode._0,
                      country: country._0,
                      additionalInformation: additionalInformation._0
                    }
                  };
          }
          var e = additionalInformation._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".additionalInformation" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function contact_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.stringToJson(v.lastName)
                  ],
                  [
                    "phoneCountryCode",
                    false,
                    Spice.stringToJson(v.phoneCountryCode)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.stringToJson(v.phoneNumber)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]
                ]));
}

function contact_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
  if (firstName.TAG === "Ok") {
    var lastName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
    if (lastName.TAG === "Ok") {
      var phoneCountryCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phoneCountryCode"), null));
      if (phoneCountryCode.TAG === "Ok") {
        var phoneNumber = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
          if (email.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      firstName: firstName._0,
                      lastName: lastName._0,
                      phoneCountryCode: phoneCountryCode._0,
                      phoneNumber: phoneNumber._0,
                      email: email._0
                    }
                  };
          }
          var e = email._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".email" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = phoneCountryCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phoneCountryCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = lastName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".lastName" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = firstName._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstName" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function billingInfo_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "contact",
                    false,
                    contact_encode(v.contact)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ]
                ]));
}

function billingInfo_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var contact = contact_decode(Belt_Option.getWithDefault(Js_dict.get(v, "contact"), null));
  if (contact.TAG === "Ok") {
    var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
    if (address.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                contact: contact._0,
                address: address._0
              }
            };
    }
    var e = address._0;
    return {
            TAG: "Error",
            _0: {
              path: ".address" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = contact._0;
  return {
          TAG: "Error",
          _0: {
            path: ".contact" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryRequestUuid",
                    false,
                    Curry._1(Identifiers.DeliveryRequestUUID.t_encode, v.deliveryRequestUuid)
                  ],
                  [
                    "deliverySlotUuid",
                    false,
                    Curry._1(Identifiers.DeliverySlotRequestIdC1.t_encode, v.deliverySlotUuid)
                  ],
                  [
                    "billingInfo",
                    false,
                    billingInfo_encode(v.billingInfo)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryRequestUuid = Curry._1(Identifiers.DeliveryRequestUUID.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryRequestUuid"), null));
  if (deliveryRequestUuid.TAG === "Ok") {
    var deliverySlotUuid = Curry._1(Identifiers.DeliverySlotRequestIdC1.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliverySlotUuid"), null));
    if (deliverySlotUuid.TAG === "Ok") {
      var billingInfo = billingInfo_decode(Belt_Option.getWithDefault(Js_dict.get(v, "billingInfo"), null));
      if (billingInfo.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  deliveryRequestUuid: deliveryRequestUuid._0,
                  deliverySlotUuid: deliverySlotUuid._0,
                  billingInfo: billingInfo._0
                }
              };
      }
      var e = billingInfo._0;
      return {
              TAG: "Error",
              _0: {
                path: ".billingInfo" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = deliverySlotUuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliverySlotUuid" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = deliveryRequestUuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryRequestUuid" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "merchantId",
                    false,
                    Spice.stringToJson(v.merchantId)
                  ],
                  [
                    "len",
                    false,
                    Spice.intToJson(v.len)
                  ],
                  [
                    "data",
                    false,
                    Spice.stringToJson(v.data)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var merchantId = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "merchantId"), null));
  if (merchantId.TAG === "Ok") {
    var len = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "len"), null));
    if (len.TAG === "Ok") {
      var data = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "data"), null));
      if (data.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  merchantId: merchantId._0,
                  len: len._0,
                  data: data._0
                }
              };
      }
      var e = data._0;
      return {
              TAG: "Error",
              _0: {
                path: ".data" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = len._0;
    return {
            TAG: "Error",
            _0: {
              path: ".len" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = merchantId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".merchantId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function config(body) {
  return {
          kyInstance: kyInstance$1,
          path: "encrypt",
          requestOptions: {
            method: "POST",
            json: body_encode(body)
          },
          mapCustomErrors: (function (error) {
              var match = error.response;
              return $$Promise.resolved(match !== undefined && match.status === 404 ? ({
                              TAG: "Custom",
                              _0: "DATA_ENCRYPTION_FAILED"
                            }) : ({
                              TAG: "Unknown",
                              _0: error
                            }));
            })
        };
}

var PostEncrypt = {
  address_encode: address_encode,
  address_decode: address_decode,
  contact_encode: contact_encode,
  contact_decode: contact_decode,
  billingInfo_encode: billingInfo_encode,
  billingInfo_decode: billingInfo_decode,
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  config: config
};

var Deliveries = {
  kyInstance: kyInstance$1,
  PostEncrypt: PostEncrypt
};

var Axcepta = {
  Deliveries: Deliveries
};

var Public = {
  Axcepta: Axcepta
};

var kyInstance$2 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/online-payment/external"
    });

var kyInstance$3 = kyInstance$2.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/online-payment/external/axcepta"
    });

var kyInstance$4 = kyInstance$3.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/online-payment/external/axcepta/transactions"
    });

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transactionUuid",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.PaymentTransactionUUID.t_encode, v.transactionUuid)
                  ],
                  [
                    "deliveryUuid",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.DeliveryUuid.t_encode, v.deliveryUuid)
                  ],
                  [
                    "transactionStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode((function (param) {
                            return Spice.arrayToJson(t_encode, param);
                          }), v.transactionStatus)
                  ]
                ]));
}

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transactionUuid = Toolkit__Decoders.$$Option.t_decode(Identifiers.PaymentTransactionUUID.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transactionUuid"), null));
  if (transactionUuid.TAG === "Ok") {
    var deliveryUuid = Toolkit__Decoders.$$Option.t_decode(Identifiers.DeliveryUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUuid"), null));
    if (deliveryUuid.TAG === "Ok") {
      var transactionStatus = Toolkit__Decoders.$$Option.t_decode((function (param) {
              return Spice.arrayFromJson(t_decode, param);
            }), Belt_Option.getWithDefault(Js_dict.get(v, "transactionStatus"), null));
      if (transactionStatus.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  transactionUuid: transactionUuid._0,
                  deliveryUuid: deliveryUuid._0,
                  transactionStatus: transactionStatus._0
                }
              };
      }
      var e = transactionStatus._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transactionStatus" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = deliveryUuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryUuid" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = transactionUuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transactionUuid" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function sort_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.createdAt)
                  ],
                  [
                    "transactionUuid",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.transactionUuid)
                  ],
                  [
                    "transactionStatus",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.transactionStatus)
                  ]
                ]));
}

function sort_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var createdAt = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
  if (createdAt.TAG === "Ok") {
    var transactionUuid = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transactionUuid"), null));
    if (transactionUuid.TAG === "Ok") {
      var transactionStatus = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transactionStatus"), null));
      if (transactionStatus.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  createdAt: createdAt._0,
                  transactionUuid: transactionUuid._0,
                  transactionStatus: transactionStatus._0
                }
              };
      }
      var e = transactionStatus._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transactionStatus" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = transactionUuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".transactionUuid" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = createdAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".createdAt" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sort",
                    false,
                    sort_encode(v.sort)
                  ],
                  [
                    "pagination",
                    false,
                    ColiswebApi__Utils.Pagination.t_encode(v.pagination)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sort = sort_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sort"), null));
    if (sort.TAG === "Ok") {
      var pagination = ColiswebApi__Utils.Pagination.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pagination"), null));
      if (pagination.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  filters: filters._0,
                  sort: sort._0,
                  pagination: pagination._0
                }
              };
      }
      var e = pagination._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagination" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = sort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sort" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function transaction_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transactionUuid",
                    false,
                    Curry._1(Identifiers.PaymentTransactionUUID.t_encode, v.transactionUuid)
                  ],
                  [
                    "deliveryUuid",
                    false,
                    Curry._1(Identifiers.DeliveryUuid.t_encode, v.deliveryUuid)
                  ],
                  [
                    "transactionStatus",
                    false,
                    t_encode(v.transactionStatus)
                  ],
                  [
                    "amount",
                    false,
                    Spice.floatToJson(v.amount)
                  ],
                  [
                    "createdAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.createdAt)
                  ]
                ]));
}

function transaction_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transactionUuid = Curry._1(Identifiers.PaymentTransactionUUID.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transactionUuid"), null));
  if (transactionUuid.TAG === "Ok") {
    var deliveryUuid = Curry._1(Identifiers.DeliveryUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryUuid"), null));
    if (deliveryUuid.TAG === "Ok") {
      var transactionStatus = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "transactionStatus"), null));
      if (transactionStatus.TAG === "Ok") {
        var amount = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "amount"), null));
        if (amount.TAG === "Ok") {
          var createdAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "createdAt"), null));
          if (createdAt.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      transactionUuid: transactionUuid._0,
                      deliveryUuid: deliveryUuid._0,
                      transactionStatus: transactionStatus._0,
                      amount: amount._0,
                      createdAt: createdAt._0
                    }
                  };
          }
          var e = createdAt._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".createdAt" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = amount._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".amount" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = transactionStatus._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transactionStatus" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = deliveryUuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryUuid" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = transactionUuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transactionUuid" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "transactions",
                    false,
                    Spice.arrayToJson(transaction_encode, v.transactions)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
  if (pagesCount.TAG === "Ok") {
    var transactions = Spice.arrayFromJson(transaction_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transactions"), null));
    if (transactions.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                pagesCount: pagesCount._0,
                transactions: transactions._0
              }
            };
    }
    var e = transactions._0;
    return {
            TAG: "Error",
            _0: {
              path: ".transactions" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = pagesCount._0;
  return {
          TAG: "Error",
          _0: {
            path: ".pagesCount" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var error_encode = Spice.unitToJson;

var error_decode = Spice.unitFromJson;

function config$1(body) {
  return {
          kyInstance: kyInstance$4,
          path: "",
          requestOptions: {
            method: "POST",
            json: body_encode$1(body)
          },
          key: [
            "ColiswebApi__V6_OnlinePayment",
            "TransactionsGetAllPaginated",
            JSON.stringify(body_encode$1(body))
          ]
        };
}

var GetAllPaginated = {
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sort_encode: sort_encode,
  sort_decode: sort_decode,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  transaction_encode: transaction_encode,
  transaction_decode: transaction_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config$1
};

var response_encode$2 = Spice.unitToJson;

var response_decode$2 = Spice.unitFromJson;

var error_encode$1 = Spice.unitToJson;

var error_decode$1 = Spice.unitFromJson;

function config$2(transcationUuid) {
  return {
          kyInstance: kyInstance$4,
          path: transcationUuid + "/refund",
          requestOptions: {
            method: "PUT"
          }
        };
}

var Refund = {
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  error_encode: error_encode$1,
  error_decode: error_decode$1,
  config: config$2
};

var Transactions = {
  kyInstance: kyInstance$4,
  GetAllPaginated: GetAllPaginated,
  Refund: Refund
};

var Axcepta$1 = {
  kyInstance: kyInstance$3,
  Transactions: Transactions
};

var $$External = {
  kyInstance: kyInstance$2,
  Axcepta: Axcepta$1
};

export {
  kyInstance ,
  Shared ,
  Public ,
  $$External ,
}
/* kyInstance Not a pure module */
