

import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";

function t_encode(v) {
  switch (v) {
    case "idle" :
        return "idle";
    case "course_refused" :
        return "course_refused";
    case "course_accepted" :
        return "course_accepted";
    case "arrival_at_pickup_point" :
        return "arrival_at_pickup_point";
    case "arrival_at_shipping_point" :
        return "arrival_at_shipping_point";
    case "package_withdrawn" :
        return "package_withdrawn";
    case "package_withdrawal_pb_ok" :
        return "package_withdrawal_pb_ok";
    case "delivery_failed" :
        return "delivery_failed";
    case "delivered" :
        return "delivered";
    case "package_withdrawal_failed" :
        return "package_withdrawal_failed";
    case "delivery_pb_ok" :
        return "delivery_pb_ok";
    case "package_returned" :
        return "package_returned";
    case "package_return_failed" :
        return "package_return_failed";
    case "canceled" :
        return "canceled";
    case "non_deliverable" :
        return "non_deliverable";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("idle" === v) {
          return {
                  TAG: "Ok",
                  _0: "idle"
                };
        } else if ("course_refused" === v) {
          return {
                  TAG: "Ok",
                  _0: "course_refused"
                };
        } else if ("course_accepted" === v) {
          return {
                  TAG: "Ok",
                  _0: "course_accepted"
                };
        } else if ("arrival_at_pickup_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "arrival_at_pickup_point"
                };
        } else if ("arrival_at_shipping_point" === v) {
          return {
                  TAG: "Ok",
                  _0: "arrival_at_shipping_point"
                };
        } else if ("package_withdrawn" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawn"
                };
        } else if ("package_withdrawal_pb_ok" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawal_pb_ok"
                };
        } else if ("delivery_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_failed"
                };
        } else if ("delivered" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivered"
                };
        } else if ("package_withdrawal_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_withdrawal_failed"
                };
        } else if ("delivery_pb_ok" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_pb_ok"
                };
        } else if ("package_returned" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_returned"
                };
        } else if ("package_return_failed" === v) {
          return {
                  TAG: "Ok",
                  _0: "package_return_failed"
                };
        } else if ("canceled" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled"
                };
        } else if ("non_deliverable" === v) {
          return {
                  TAG: "Ok",
                  _0: "non_deliverable"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode;

function isOriginRelated(x) {
  switch (x) {
    case "arrival_at_shipping_point" :
    case "delivery_failed" :
    case "delivered" :
    case "delivery_pb_ok" :
    case "package_returned" :
    case "package_return_failed" :
    case "non_deliverable" :
        return false;
    default:
      return true;
  }
}

function isDestinationRelated(x) {
  switch (x) {
    case "arrival_at_shipping_point" :
    case "delivery_failed" :
    case "delivered" :
    case "delivery_pb_ok" :
        return true;
    default:
      return false;
  }
}

function isFailure(x) {
  switch (x) {
    case "course_refused" :
    case "arrival_at_pickup_point" :
    case "arrival_at_shipping_point" :
    case "delivery_failed" :
    case "package_withdrawal_failed" :
    case "package_return_failed" :
        return true;
    default:
      return false;
  }
}

function isSuccess(x) {
  switch (x) {
    case "idle" :
    case "course_refused" :
    case "arrival_at_pickup_point" :
    case "arrival_at_shipping_point" :
    case "delivery_failed" :
    case "package_withdrawal_failed" :
    case "canceled" :
        return false;
    default:
      return true;
  }
}

function isInformative(x) {
  switch (x) {
    case "arrival_at_pickup_point" :
    case "arrival_at_shipping_point" :
        return true;
    default:
      return false;
  }
}

function isReturnRelated(x) {
  switch (x) {
    case "package_returned" :
    case "package_return_failed" :
        return true;
    default:
      return false;
  }
}

function isWithdrawed(x) {
  switch (x) {
    case "idle" :
    case "course_refused" :
    case "course_accepted" :
    case "arrival_at_pickup_point" :
    case "package_withdrawal_failed" :
    case "canceled" :
    case "non_deliverable" :
        return false;
    default:
      return true;
  }
}

var StatusProvider = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString,
  isOriginRelated: isOriginRelated,
  isDestinationRelated: isDestinationRelated,
  isFailure: isFailure,
  isSuccess: isSuccess,
  isInformative: isInformative,
  isReturnRelated: isReturnRelated,
  isWithdrawed: isWithdrawed
};

function storeOwnerAddress_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ]
                ]));
}

function storeOwnerAddress_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
        if (city.TAG === "Ok") {
          var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
          if (country.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      line1: line1._0,
                      line2: line2._0,
                      postalCode: postalCode._0,
                      city: city._0,
                      country: country._0
                    }
                  };
          }
          var e = country._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".country" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = city._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".city" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function storeOwner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    storeOwnerAddress_encode(v.address)
                  ]
                ]));
}

function storeOwner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var address = storeOwnerAddress_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
    if (address.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                name: name._0,
                address: address._0
              }
            };
    }
    var e = address._0;
    return {
            TAG: "Error",
            _0: {
              path: ".address" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function clientOwner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function clientOwner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function t_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "store",
                    false,
                    Spice.optionToJson(storeOwner_encode, v.store)
                  ],
                  [
                    "client",
                    false,
                    Spice.optionToJson(clientOwner_encode, v.client)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ]
                ]));
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var store = Spice.optionFromJson(storeOwner_decode, Belt_Option.getWithDefault(Js_dict.get(v, "store"), null));
  if (store.TAG === "Ok") {
    var client = Spice.optionFromJson(clientOwner_decode, Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
    if (client.TAG === "Ok") {
      var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
      if (storeId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  store: store._0,
                  client: client._0,
                  storeId: storeId._0
                }
              };
      }
      var e = storeId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = client._0;
    return {
            TAG: "Error",
            _0: {
              path: ".client" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = store._0;
  return {
          TAG: "Error",
          _0: {
            path: ".store" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var Owner = {
  storeOwnerAddress_encode: storeOwnerAddress_encode,
  storeOwnerAddress_decode: storeOwnerAddress_decode,
  storeOwner_encode: storeOwner_encode,
  storeOwner_decode: storeOwner_decode,
  clientOwner_encode: clientOwner_encode,
  clientOwner_decode: clientOwner_decode,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function t_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.name)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.lastName)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "country",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.country)
                  ],
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "comment",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.comment)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "coords",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Coordinates.t_encode, v.coords)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.Lift.t_encode, v.lift)
                  ],
                  [
                    "locationType",
                    false,
                    ColiswebApi__V5_Utils.LocationType.t_encode(v.locationType)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "isDeleted",
                    false,
                    Spice.boolToJson(v.isDeleted)
                  ]
                ]));
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var firstName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var postalCode = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "postalCode"), Spice.stringFromJson), {
              TAG: "Ok",
              _0: ""
            });
        if (postalCode.TAG === "Ok") {
          var city = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "city"), Spice.stringFromJson), {
                TAG: "Ok",
                _0: ""
              });
          if (city.TAG === "Ok") {
            var country = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
            if (country.TAG === "Ok") {
              var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
              if (line1.TAG === "Ok") {
                var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
                if (line2.TAG === "Ok") {
                  var comment = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "comment"), null));
                  if (comment.TAG === "Ok") {
                    var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                    if (floor.TAG === "Ok") {
                      var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
                      if (phone1.TAG === "Ok") {
                        var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
                        if (phone2.TAG === "Ok") {
                          var coords = Spice.optionFromJson(Toolkit__Decoders.Coordinates.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "coords"), null));
                          if (coords.TAG === "Ok") {
                            var lift = Spice.optionFromJson(ColiswebApi__V5_Utils.Lift.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
                            if (lift.TAG === "Ok") {
                              var locationType = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "locationType"), ColiswebApi__V5_Utils.LocationType.t_decode), {
                                    TAG: "Ok",
                                    _0: "hub"
                                  });
                              if (locationType.TAG === "Ok") {
                                var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
                                if (storeId.TAG === "Ok") {
                                  var isDeleted = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isDeleted"), Spice.boolFromJson), {
                                        TAG: "Ok",
                                        _0: false
                                      });
                                  if (isDeleted.TAG === "Ok") {
                                    return {
                                            TAG: "Ok",
                                            _0: {
                                              name: name._0,
                                              firstName: firstName._0,
                                              lastName: lastName._0,
                                              postalCode: postalCode._0,
                                              city: city._0,
                                              country: country._0,
                                              line1: line1._0,
                                              line2: line2._0,
                                              comment: comment._0,
                                              floor: floor._0,
                                              phone1: phone1._0,
                                              phone2: phone2._0,
                                              coords: coords._0,
                                              lift: lift._0,
                                              locationType: locationType._0,
                                              storeId: storeId._0,
                                              isDeleted: isDeleted._0
                                            }
                                          };
                                  }
                                  var e = isDeleted._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".isDeleted" + e.path,
                                            message: e.message,
                                            value: e.value
                                          }
                                        };
                                }
                                var e$1 = storeId._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".storeId" + e$1.path,
                                          message: e$1.message,
                                          value: e$1.value
                                        }
                                      };
                              }
                              var e$2 = locationType._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".locationType" + e$2.path,
                                        message: e$2.message,
                                        value: e$2.value
                                      }
                                    };
                            }
                            var e$3 = lift._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".lift" + e$3.path,
                                      message: e$3.message,
                                      value: e$3.value
                                    }
                                  };
                          }
                          var e$4 = coords._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".coords" + e$4.path,
                                    message: e$4.message,
                                    value: e$4.value
                                  }
                                };
                        }
                        var e$5 = phone2._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".phone2" + e$5.path,
                                  message: e$5.message,
                                  value: e$5.value
                                }
                              };
                      }
                      var e$6 = phone1._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".phone1" + e$6.path,
                                message: e$6.message,
                                value: e$6.value
                              }
                            };
                    }
                    var e$7 = floor._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".floor" + e$7.path,
                              message: e$7.message,
                              value: e$7.value
                            }
                          };
                  }
                  var e$8 = comment._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".comment" + e$8.path,
                            message: e$8.message,
                            value: e$8.value
                          }
                        };
                }
                var e$9 = line2._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".line2" + e$9.path,
                          message: e$9.message,
                          value: e$9.value
                        }
                      };
              }
              var e$10 = line1._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".line1" + e$10.path,
                        message: e$10.message,
                        value: e$10.value
                      }
                    };
            }
            var e$11 = country._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".country" + e$11.path,
                      message: e$11.message,
                      value: e$11.value
                    }
                  };
          }
          var e$12 = city._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".city" + e$12.path,
                    message: e$12.message,
                    value: e$12.value
                  }
                };
        }
        var e$13 = postalCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".postalCode" + e$13.path,
                  message: e$13.message,
                  value: e$13.value
                }
              };
      }
      var e$14 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$14.path,
                message: e$14.message,
                value: e$14.value
              }
            };
    }
    var e$15 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$15.path,
              message: e$15.message,
              value: e$15.value
            }
          };
  }
  var e$16 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$16.path,
            message: e$16.message,
            value: e$16.value
          }
        };
}

var Point = {
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function t_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "isSidewalk",
                    false,
                    Spice.boolToJson(v.isSidewalk)
                  ],
                  [
                    "isInRoom",
                    false,
                    Spice.boolToJson(v.isInRoom)
                  ],
                  [
                    "isTrailed",
                    false,
                    Spice.boolToJson(v.isTrailed)
                  ]
                ]));
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isSidewalk = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isSidewalk"), null));
  if (isSidewalk.TAG === "Ok") {
    var isInRoom = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isInRoom"), null));
    if (isInRoom.TAG === "Ok") {
      var isTrailed = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isTrailed"), null));
      if (isTrailed.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  isSidewalk: isSidewalk._0,
                  isInRoom: isInRoom._0,
                  isTrailed: isTrailed._0
                }
              };
      }
      var e = isTrailed._0;
      return {
              TAG: "Error",
              _0: {
                path: ".isTrailed" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = isInRoom._0;
    return {
            TAG: "Error",
            _0: {
              path: ".isInRoom" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = isSidewalk._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isSidewalk" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var HeftingOptions = {
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

var Delivery = {
  Owner: Owner,
  Point: Point,
  HeftingOptions: HeftingOptions
};

export {
  StatusProvider ,
  Delivery ,
}
/* Identifiers Not a pure module */
