

import * as Js_dict from "../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";

var playgroundJsModules = import.meta.glob("@root/lib/es6_global/playground/components/*.js", {
      eager: true,
      import: "make"
    });

var files = Belt_Array.reduce(Js_dict.entries(import.meta.glob("@root/playground/components/**/*.res", {
              as: "raw",
              eager: true
            })), [], (function (acc, param) {
        var parsedFilePathname = param[0].replace("/playground/components/", "").replace(".res", "").split("/")[0];
        var jsModule = playgroundJsModules["/lib/es6_global/playground/components/" + parsedFilePathname + ".bs.js"];
        var component_name = parsedFilePathname.replace("Playground_", "");
        var component_codeExample = param[1];
        var component = {
          name: component_name,
          jsModule: jsModule,
          codeExample: component_codeExample
        };
        acc.push(component);
        return acc;
      }));

export {
  playgroundJsModules ,
  files ,
}
/* playgroundJsModules Not a pure module */
