

import * as Cx from "../../../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V6_Parcel from "../../../v6/ColiswebApi__V6_Parcel.bs.js";
import ToThrowSvgreact from "@apiRoot/src/components/parcel/directive-tag/assets/toThrow.svg?react";
import ToReturnSvgreact from "@apiRoot/src/components/parcel/directive-tag/assets/toReturn.svg?react";
import ToDeliverSvgreact from "@apiRoot/src/components/parcel/directive-tag/assets/toDeliver.svg?react";

function ApiComponents__ParcelDirectiveTag(props) {
  var directive = props.directive;
  var intl = ReactIntl.useIntl();
  var tmp;
  var exit = 0;
  switch (directive) {
    case "toDeliver" :
        tmp = null;
        break;
    case "toReturn" :
    case "toThrow" :
        exit = 1;
        break;
    case "toDecide" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(Fa.FaQuestionCircle, {
                    size: 16
                  }),
              className: "bg-warning-700 rounded-l text-white flex-shrink-0 py-2 px-1"
            });
        break;
    
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx("span", {
          children: JsxRuntime.jsx(Fa.FaExclamationTriangle, {
                size: 16
              }),
          className: "bg-danger-700 rounded-l text-white flex-shrink-0 py-2 px-1"
        });
  }
  var tmp$1;
  switch (directive) {
    case "toDeliver" :
        tmp$1 = JsxRuntime.jsx(ToDeliverSvgreact, {
              className: "bg-white rounded w-6 h-6"
            });
        break;
    case "toReturn" :
        tmp$1 = JsxRuntime.jsx(ToReturnSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "toThrow" :
        tmp$1 = JsxRuntime.jsx(ToThrowSvgreact, {
              className: "w-5 h-5"
            });
        break;
    case "toDecide" :
        tmp$1 = null;
        break;
    
  }
  var tmp$2;
  switch (directive) {
    case "toDeliver" :
        tmp$2 = "";
        break;
    case "toReturn" :
    case "toThrow" :
        tmp$2 = "border border-danger-700 rounded-r px-2";
        break;
    case "toDecide" :
        tmp$2 = "border border-warning-700 rounded-r px-2";
        break;
    
  }
  var tmp$3;
  switch (directive) {
    case "toDeliver" :
        tmp$3 = "text-neutral-800 bg-neutral-100 p-1";
        break;
    case "toReturn" :
    case "toThrow" :
        tmp$3 = "text-danger-700 bg-danger-50";
        break;
    case "toDecide" :
        tmp$3 = "text-warning-700 bg-warning-50";
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        tmp$1,
                        JsxRuntime.jsx("p", {
                              children: ColiswebApi__V6_Parcel.Directive.humanize(intl, directive),
                              className: "text-sm font-semibold uppercase"
                            })
                      ],
                      className: Cx.cx([
                            "flex flex-row items-center gap-2",
                            tmp$2
                          ])
                    })
              ],
              className: Cx.cx([
                    "inline-flex self-start flex-row rounded-md  justify-between",
                    tmp$3
                  ])
            });
}

var make = ApiComponents__ParcelDirectiveTag;

export {
  make ,
}
/* react-intl Not a pure module */
