

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Md from "react-icons/md";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Ui_Card from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Card.bs.js";
import * as React from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as ApiComponents__Hooks from "./ApiComponents__Hooks.bs.js";
import * as Toolkit__BrowserLogger from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/logger/Toolkit__BrowserLogger.bs.js";

function ApiComponents__ErrorPlaceholder$PlaceholderCard(props) {
  return JsxRuntime.jsxs(Toolkit__Ui_Card.make, {
              className: "mx-auto mt-24 max-w-lg",
              children: [
                JsxRuntime.jsx("img", {
                      className: "w-full h-64 p-4",
                      alt: "error",
                      src: props.img
                    }),
                JsxRuntime.jsxs(Toolkit__Ui_Card.Body.make, {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: props.title,
                              className: "font-semibold font-display text-xl mb-2"
                            }),
                        JsxRuntime.jsx("p", {
                              children: props.message,
                              className: "text-gray-700"
                            }),
                        Belt_Option.getWithDefault(props.additionalContent, null)
                      ]
                    })
              ]
            });
}

var PlaceholderCard = {
  make: ApiComponents__ErrorPlaceholder$PlaceholderCard
};

function ApiComponents__ErrorPlaceholder$DecodeError(props) {
  return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder$PlaceholderCard, {
              title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_9b6fd28e",
                    defaultMessage: "Une erreur est survenue lors de la récupération des données."
                  }),
              message: JsxRuntime.jsxs(React.Disclosure, {
                    children: [
                      JsxRuntime.jsxs(React.Disclosure.Button, {
                            children: [
                              JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_83c348e3",
                                    defaultMessage: "Afficher plus d'informations"
                                  }),
                              JsxRuntime.jsx(Md.MdKeyboardArrowRight, {
                                    className: "ui-open:rotate-90 ui-open:transform"
                                  })
                            ],
                            className: "flex flex-row items-center gap-1"
                          }),
                      JsxRuntime.jsx(React.Disclosure.Panel, {
                            children: JsxRuntime.jsx("code", {
                                  children: JsxRuntime.jsx("pre", {
                                        children: JSON.stringify(props.error, null, 2),
                                        className: "font-code p-2 text-sm rounded border border-gray-300 bg-gray-100 text-neutral-700 overflow-auto"
                                      })
                                }),
                            className: "mt-2"
                          })
                    ]
                  }),
              img: ColiswebApi__Env.basePath + "assets/placeholders/decodeError.svg"
            });
}

var DecodeError = {
  make: ApiComponents__ErrorPlaceholder$DecodeError
};

function ApiComponents__ErrorPlaceholder$Default(props) {
  var error = props.error;
  if (typeof error === "object" && error.NAME === "decodeError") {
    var error$1 = error.VAL;
    Toolkit__BrowserLogger.error2("Decode error", error$1);
    return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder$DecodeError, {
                error: error$1
              });
  }
  Toolkit__BrowserLogger.error(error);
  return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder$PlaceholderCard, {
              title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_c3860a17",
                    defaultMessage: "Une erreur est survenue."
                  }),
              message: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_4036b0e5",
                    defaultMessage: "Une erreur s'est produite sur la page."
                  }),
              img: ColiswebApi__Env.basePath + "assets/placeholders/default.svg"
            });
}

var Default = {
  make: ApiComponents__ErrorPlaceholder$Default
};

var img = ColiswebApi__Env.basePath + "assets/placeholders/notAuthorized.svg";

function ApiComponents__ErrorPlaceholder$UserNotAuthorized(props) {
  var logout = ApiComponents__Hooks.useLogout();
  return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder$PlaceholderCard, {
              title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "_c3860a17",
                    defaultMessage: "Une erreur est survenue."
                  }),
              message: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                    id: "errors.notAuthorizedDetails",
                    defaultMessage: "Vous n'êtes pas autorisé à accéder à cette page."
                  }),
              additionalContent: Caml_option.some(JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                              color: "primary",
                              onClick: (function (param) {
                                  Curry._1(logout, undefined);
                                }),
                              children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                        id: "_dca4963c",
                                        defaultMessage: "Se déconnecter"
                                      }))
                            }),
                        className: "mt-4 flex justify-center"
                      })),
              img: img
            });
}

var UserNotAuthorized = {
  img: img,
  make: ApiComponents__ErrorPlaceholder$UserNotAuthorized
};

var img$1 = ColiswebApi__Env.basePath + "assets/placeholders/notFound.svg";

function ApiComponents__ErrorPlaceholder$PageNotFound(props) {
  return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder$PlaceholderCard, {
              title: Belt_Option.getWithDefault(props.title, JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_8eab6eb3",
                        defaultMessage: "Rien a voir ici."
                      })),
              message: Belt_Option.getWithDefault(props.message, JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_74c085b5",
                        defaultMessage: "Cette page n'existe pas"
                      })),
              img: img$1
            });
}

var PageNotFound = {
  img: img$1,
  make: ApiComponents__ErrorPlaceholder$PageNotFound
};

export {
  PlaceholderCard ,
  DecodeError ,
  Default ,
  UserNotAuthorized ,
  PageNotFound ,
}
/* react-intl Not a pure module */
