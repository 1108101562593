

import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Ui_Label from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Label.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_TextInput from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_TextInput.bs.js";

function AutocompleteAddress__ModalTextInput(props) {
  var error = props.error;
  var __isOptional = props.isOptional;
  var id = props.id;
  var isOptional = __isOptional !== undefined ? __isOptional : false;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(Toolkit__Ui_Label.make, {
                              htmlFor: id,
                              optionalMessage: isOptional ? Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_e5c29cb8",
                                          defaultMessage: "(Optionnel)"
                                        })) : undefined,
                              children: props.label
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Toolkit__Ui_TextInput.make, {
                                    id: id,
                                    name: props.name,
                                    value: props.value,
                                    placeholder: props.placeholder,
                                    type_: props.type_,
                                    onBlur: props.onBlur,
                                    onChange: props.onChange,
                                    isInvalid: Belt_Option.isSome(error),
                                    className: props.inputClassName
                                  }),
                              className: "flex flex-row"
                            })
                      ],
                      className: "flex flex-col"
                    }),
                Belt_Option.mapWithDefault(error, null, (function (error) {
                        return JsxRuntime.jsx("p", {
                                    children: error,
                                    className: "text-danger-600 text-sm mt-1"
                                  });
                      }))
              ]
            });
}

var make = AutocompleteAddress__ModalTextInput;

export {
  make ,
}
/* react-intl Not a pure module */
