

import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as JsxRuntime from "react/jsx-runtime";
import DamageSvgreact from "@apiRoot/src/components/parcel/damage-status-tag/assets/damage.svg?react";

function ApiComponents__ParcelDamageStatusTag(props) {
  switch (props.damageStatus) {
    case "damagedButDeliverable" :
        return JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(DamageSvgreact, {
                            className: "w-6 h-6"
                          }),
                      JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_9d333917",
                            defaultMessage: "Avarie"
                          })
                    ],
                    className: "inline-flex self-start flex-row items-center py-1 px-2 bg-warning-50 text-warning-700 rounded font-semibold uppercase gap-1 mb-2"
                  });
    case "notDamaged" :
        return null;
    case "undeliverable" :
        return JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(DamageSvgreact, {
                            className: "w-6 h-6"
                          }),
                      JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_9d333917",
                            defaultMessage: "Avarie"
                          }),
                      JsxRuntime.jsx(Fa.FaMinusCircle, {
                            size: 22,
                            className: "ml-4"
                          }),
                      JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_2e93222b",
                            defaultMessage: "Non-livrable"
                          })
                    ],
                    className: "inline-flex self-start flex-row items-center py-1 px-2 bg-danger-50 text-danger-500 rounded gap-1 mb-2 font-semibold uppercase"
                  });
    
  }
}

var make = ApiComponents__ParcelDamageStatusTag;

export {
  make ,
}
/* react-intl Not a pure module */
