

import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V6_Authent from "../v6/ColiswebApi__V6_Authent.bs.js";
import * as ApiComponents__ErrorPlaceholder from "./ApiComponents__ErrorPlaceholder.bs.js";

function ApiComponents__AccessRestrictedByRoles(props) {
  var match = $$Request.useFetcher(undefined, {
        response_decode: ColiswebApi__V6_Authent.UpdateSession.response_decode,
        config: ColiswebApi__V6_Authent.UpdateSession.config
      }, Caml_option.some(undefined));
  var session = match[0];
  if (Belt_Array.some(props.allowedRoles, (function (role) {
            return session.role === role;
          }))) {
    return props.children;
  } else {
    return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.UserNotAuthorized.make, {});
  }
}

var make = ApiComponents__AccessRestrictedByRoles;

export {
  make ,
}
/* Request Not a pure module */
