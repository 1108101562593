

import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__ErrorPlaceholder from "../../src/components/ApiComponents__ErrorPlaceholder.bs.js";

function Playground_ErrorPlaceholder(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Decode error"
                            }),
                        JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.DecodeError.make, {
                              error: {
                                path: "path",
                                message: "This not work",
                                value: ""
                              }
                            })
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Default error"
                            }),
                        JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.Default.make, {
                              error: {
                                path: "path",
                                message: "This not work",
                                value: ""
                              }
                            })
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "Not found"
                            }),
                        JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.PageNotFound.make, {})
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h3", {
                              children: "User not authorized"
                            }),
                        JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.UserNotAuthorized.make, {})
                      ]
                    })
              ],
              className: "flex flex-col gap-6"
            });
}

var make = Playground_ErrorPlaceholder;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
