

import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";

var baseUrl = "/orders";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function assignment_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporterId",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.transporterId)
                  ],
                  [
                    "carrierId",
                    false,
                    Spice.optionToJson(Identifiers.CarrierId.t_encode, v.carrierId)
                  ]
                ]));
}

function assignment_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterId = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
  if (transporterId.TAG === "Ok") {
    var carrierId = Spice.optionFromJson(Identifiers.CarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
    if (carrierId.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                transporterId: transporterId._0,
                carrierId: carrierId._0
              }
            };
    }
    var e = carrierId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".carrierId" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = transporterId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.id)
                  ],
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "ref1",
                    false,
                    Curry._1(Identifiers.DeliveryReference.t_encode, v.ref1)
                  ],
                  [
                    "ref2",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref2)
                  ],
                  [
                    "ref3",
                    false,
                    Spice.optionToJson(Identifiers.DeliveryReference.t_encode, v.ref3)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "timeSlot",
                    false,
                    ColiswebApi__V5_Utils.TimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "assignment",
                    false,
                    Spice.optionToJson(assignment_encode, v.assignment)
                  ],
                  [
                    "routeId",
                    false,
                    Spice.optionToJson(Identifiers.RouteId.t_encode, v.routeId)
                  ],
                  [
                    "candidateToRouteUntil",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.candidateToRouteUntil)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
    if (orderId.TAG === "Ok") {
      var ref1 = Curry._1(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref1"), null));
      if (ref1.TAG === "Ok") {
        var ref2 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref2"), null));
        if (ref2.TAG === "Ok") {
          var ref3 = Spice.optionFromJson(Identifiers.DeliveryReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "ref3"), null));
          if (ref3.TAG === "Ok") {
            var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
            if (statusProvider.TAG === "Ok") {
              var timeSlot = ColiswebApi__V5_Utils.TimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
              if (timeSlot.TAG === "Ok") {
                var assignment = Spice.optionFromJson(assignment_decode, Belt_Option.getWithDefault(Js_dict.get(v, "assignment"), null));
                if (assignment.TAG === "Ok") {
                  var routeId = Spice.optionFromJson(Identifiers.RouteId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "routeId"), null));
                  if (routeId.TAG === "Ok") {
                    var candidateToRouteUntil = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "candidateToRouteUntil"), null));
                    if (candidateToRouteUntil.TAG === "Ok") {
                      return {
                              TAG: "Ok",
                              _0: {
                                id: id._0,
                                orderId: orderId._0,
                                ref1: ref1._0,
                                ref2: ref2._0,
                                ref3: ref3._0,
                                statusProvider: statusProvider._0,
                                timeSlot: timeSlot._0,
                                assignment: assignment._0,
                                routeId: routeId._0,
                                candidateToRouteUntil: candidateToRouteUntil._0
                              }
                            };
                    }
                    var e = candidateToRouteUntil._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".candidateToRouteUntil" + e.path,
                              message: e.message,
                              value: e.value
                            }
                          };
                  }
                  var e$1 = routeId._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".routeId" + e$1.path,
                            message: e$1.message,
                            value: e$1.value
                          }
                        };
                }
                var e$2 = assignment._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".assignment" + e$2.path,
                          message: e$2.message,
                          value: e$2.value
                        }
                      };
              }
              var e$3 = timeSlot._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".timeSlot" + e$3.path,
                        message: e$3.message,
                        value: e$3.value
                      }
                    };
            }
            var e$4 = statusProvider._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".statusProvider" + e$4.path,
                      message: e$4.message,
                      value: e$4.value
                    }
                  };
          }
          var e$5 = ref3._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".ref3" + e$5.path,
                    message: e$5.message,
                    value: e$5.value
                  }
                };
        }
        var e$6 = ref2._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".ref2" + e$6.path,
                  message: e$6.message,
                  value: e$6.value
                }
              };
      }
      var e$7 = ref1._0;
      return {
              TAG: "Error",
              _0: {
                path: ".ref1" + e$7.path,
                message: e$7.message,
                value: e$7.value
              }
            };
    }
    var e$8 = orderId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".orderId" + e$8.path,
              message: e$8.message,
              value: e$8.value
            }
          };
  }
  var e$9 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$9.path,
            message: e$9.message,
            value: e$9.value
          }
        };
}

function argument_encode(v) {
  return Curry._1(Identifiers.OrderId.t_encode, v);
}

function argument_decode(v) {
  return Curry._1(Identifiers.OrderId.t_decode, v);
}

function response_encode(v) {
  return Spice.arrayToJson(delivery_encode, v);
}

function response_decode(v) {
  return Spice.arrayFromJson(delivery_decode, v);
}

function exec(orderId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, undefined, baseUrl + "/" + orderId + "/deliveries");
}

var Config = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  assignment_encode: assignment_encode,
  assignment_decode: assignment_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var GetOrderDeliveries = {
  Config: Config,
  $$Request: $$Request
};

function orderContainer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ]]));
}

function orderContainer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orderId: orderId._0
            }
          };
  }
  var e = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function argument_encode$1(v) {
  return [
          Curry._1(Identifiers.HubId.t_encode, v[0]),
          Spice.arrayToJson(Identifiers.OrderId.t_encode, v[1])
        ];
}

function argument_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.HubId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(Identifiers.OrderId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(orderContainer_encode, v.orders)
                  ]]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(orderContainer_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function order_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orderId = Curry._1(Identifiers.OrderId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orderId"), null));
  if (orderId.TAG === "Ok") {
    var deliveryDate = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryDate"), null));
    if (deliveryDate.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                orderId: orderId._0,
                deliveryDate: deliveryDate._0
              }
            };
    }
    var e = deliveryDate._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveryDate" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = orderId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orderId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function order_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "orderId",
                    false,
                    Curry._1(Identifiers.OrderId.t_encode, v.orderId)
                  ],
                  [
                    "deliveryDate",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.deliveryDate)
                  ]
                ]));
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "orders",
                    false,
                    Spice.arrayToJson(order_encode, v.orders)
                  ]]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var orders = Spice.arrayFromJson(order_decode, Belt_Option.getWithDefault(Js_dict.get(v, "orders"), null));
  if (orders.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              orders: orders._0
            }
          };
  }
  var e = orders._0;
  return {
          TAG: "Error",
          _0: {
            path: ".orders" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$1(param) {
  return Axios.WithResult.post(undefined, body_encode({
                  orders: Belt_Array.map(param[1], (function (orderId) {
                          return {
                                  orderId: orderId
                                };
                        }))
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, undefined, "/hubs/" + param[0] + "/" + baseUrl + "/deliveryDate");
}

var Config$1 = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  body_encode: body_encode,
  body_decode: body_decode,
  orderContainer_encode: orderContainer_encode,
  orderContainer_decode: orderContainer_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  order_encode: order_encode,
  order_decode: order_decode,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var GetOrderDeliveryDates = {
  Config: Config$1,
  $$Request: $$Request$1
};

export {
  baseUrl ,
  config ,
  GetOrderDeliveries ,
  GetOrderDeliveryDates ,
}
/* Request Not a pure module */
