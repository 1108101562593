

import * as Cx from "../../../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as ReactIntl from "react-intl";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V6_Parcel from "../../../v6/ColiswebApi__V6_Parcel.bs.js";
import DamageSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/damage.svg?react";
import ThrownSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/thrown.svg?react";
import LoadingSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/loading.svg?react";
import ShuttleSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/shuttle.svg?react";
import InventorySvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/inventory.svg?react";
import RouteControlSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/routeControl.svg?react";
import CarrierReturnSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/carrierReturn.svg?react";
import ReturnToOwnerSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/returnToOwner.svg?react";
import RoutePreparationSvgreact from "@apiRoot/src/components/parcel/scan-context-tag/assets/routePreparation.svg?react";

function ApiComponents__ParcelScanContextTag$Icon(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var tmp;
  switch (props.scanContext) {
    case "shuttle" :
        tmp = JsxRuntime.jsx(ShuttleSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "routePreparation" :
        tmp = JsxRuntime.jsx(RoutePreparationSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "inventory" :
        tmp = JsxRuntime.jsx(InventorySvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "loading" :
        tmp = JsxRuntime.jsx(LoadingSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "carrierReturn" :
        tmp = JsxRuntime.jsx(CarrierReturnSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "damage" :
        tmp = JsxRuntime.jsx(DamageSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "thrown" :
        tmp = JsxRuntime.jsx(ThrownSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "carrierPickup" :
    case "carrierShipping" :
        tmp = JsxRuntime.jsx(Md.MdCheckCircle, {
              size: 22
            });
        break;
    case "returnToOwner" :
        tmp = JsxRuntime.jsx(ReturnToOwnerSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "routeControl" :
    case "wrongRouteControl" :
        tmp = JsxRuntime.jsx(RouteControlSvgreact, {
              className: "w-6 h-6"
            });
        break;
    case "receivedByOwner" :
        tmp = JsxRuntime.jsx(Fa.FaUsers, {
              size: 22
            });
        break;
    case "lost" :
        tmp = JsxRuntime.jsx(Fa.FaExclamationTriangle, {
              size: 20
            });
        break;
    case "technicalCleaning" :
        tmp = null;
        break;
    
  }
  return JsxRuntime.jsx("span", {
              children: tmp,
              className: className
            });
}

var Icon = {
  make: ApiComponents__ParcelScanContextTag$Icon
};

function ApiComponents__ParcelScanContextTag(props) {
  var scanContext = props.scanContext;
  var intl = ReactIntl.useIntl();
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(Md.MdBarcodeReader, {
                            size: 20
                          }),
                      className: "bg-neutral-500 rounded-l-md text-white flex-shrink-0 py-2 px-1"
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(ApiComponents__ParcelScanContextTag$Icon, {
                              className: "text-neutral-600",
                              scanContext: scanContext
                            }),
                        JsxRuntime.jsx("p", {
                              children: ColiswebApi__V6_Parcel.ScanContext.humanize(intl, scanContext),
                              className: "text-sm text-neutral-600 font-semibold"
                            })
                      ],
                      className: Cx.cx(["flex flex-row items-center gap-1 border border-neutral-500 rounded-r-md px-1"])
                    })
              ],
              className: Cx.cx(["inline-flex self-start flex-row rounded  justify-between"])
            });
}

var make = ApiComponents__ParcelScanContextTag;

export {
  Icon ,
  make ,
}
/* react-intl Not a pure module */
