

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__TableFilters from "../../src/components/ApiComponents__TableFilters.bs.js";

function Playground_TableFilters(props) {
  var match = React.useState(function (param) {
        return [];
      });
  var setDefaultValue = match[1];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__TableFilters.StoreCodesMultiSelect.make, {
                    setFilter: (function (values) {
                        Curry._1(setDefaultValue, (function (param) {
                                return Belt_Option.getWithDefault(values, []);
                              }));
                      }),
                    defaultValue: match[0]
                  })
            });
}

var make = Playground_TableFilters;

export {
  make ,
}
/* react Not a pure module */
