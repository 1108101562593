

import * as React from "react";
import * as Browser from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Browser.bs.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as ColiswebApi__V6_Authent from "../v6/ColiswebApi__V6_Authent.bs.js";

function useLogout(param) {
  return React.useCallback((function (param) {
                $$Promise.tapOk($$Request.fetchAPI({
                          response_decode: ColiswebApi__V6_Authent.DeleteSession.response_decode,
                          config: ColiswebApi__V6_Authent.DeleteSession.config
                        }, undefined), (function (param) {
                        Browser.$$Location.replace(Browser.$$Location.$$location, ColiswebApi__Env.boLoginUrl);
                      }));
              }), []);
}

function useUser(param) {
  return $$Request.useFetcher(undefined, {
                response_decode: ColiswebApi__V6_Authent.UpdateSession.response_decode,
                config: ColiswebApi__V6_Authent.UpdateSession.config
              }, Caml_option.some(undefined))[0];
}

export {
  useLogout ,
  useUser ,
}
/* react Not a pure module */
