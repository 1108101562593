

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import SortBy from "lodash/sortBy";
import * as PlaygroundUtils from "./PlaygroundUtils.bs.js";
import * as PlaygroundRouter from "./PlaygroundRouter.bs.js";
import * as React$1 from "@headlessui/react";
import * as JsxRuntime from "react/jsx-runtime";
import * as Playground_CodeBlock from "./Playground_CodeBlock.bs.js";

function PlaygroundComponents$List(props) {
  var match = React.useState(function (param) {
        return "";
      });
  var setSearch = match[1];
  var search = match[0];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("h1", {
                      children: [
                        "Components",
                        JsxRuntime.jsx("input", {
                              className: "bg-white text-sm font-sans px-4 font-normal  rounded-md border border-neutral-300 focus:border-primary-700 h-10 w-60",
                              placeholder: "Search",
                              type: "search",
                              value: search,
                              onChange: (function ($$event) {
                                  var target = $$event.target;
                                  Curry._1(setSearch, (function (param) {
                                          return target.value;
                                        }));
                                })
                            })
                      ],
                      className: "text-4xl font-bold font-display mb-4 flex flex-row gap-4 items-center"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(Belt_Array.keep(SortBy(PlaygroundUtils.files, (function (param) {
                                      return param.name.toLowerCase();
                                    })), (function (param) {
                                  return param.name.toLowerCase().includes(search);
                                })), (function (param) {
                              var name = param.name;
                              return JsxRuntime.jsx(PlaygroundRouter.Link.make, {
                                          route: {
                                            TAG: "Components",
                                            _0: {
                                              TAG: "Details",
                                              _0: name
                                            }
                                          },
                                          className: "p-4 rounded-lg border bg-white hover:border-primary-700 w-64",
                                          children: JsxRuntime.jsx("strong", {
                                                children: name,
                                                className: "capitalize"
                                              })
                                        }, name);
                            })),
                      className: "flex flex-row flex-wrap gap-4"
                    })
              ]
            });
}

var List = {
  make: PlaygroundComponents$List
};

function PlaygroundComponents$Details(props) {
  var component = props.component;
  Curry._1(PlaygroundRouter.Breadcrumb.use, [
        {
          route: {
            TAG: "Components",
            _0: "List"
          },
          text: "Components"
        },
        {
          route: {
            TAG: "Components",
            _0: {
              TAG: "Details",
              _0: component
            }
          },
          text: JsxRuntime.jsx("span", {
                children: component,
                className: "capitalize"
              })
        }
      ]);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h1", {
                      children: component,
                      className: "text-4xl font-bold font-display mb-4 capitalize print:hidden"
                    }),
                JsxRuntime.jsx("div", {
                      children: Belt_Option.mapWithDefault(Belt_Array.getBy(PlaygroundUtils.files, (function (param) {
                                  return component.toLocaleLowerCase() === param.name.toLocaleLowerCase();
                                })), "unknown", (function (param) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsxs(React$1.Tab.Group, {
                                                children: [
                                                  JsxRuntime.jsxs(React$1.Tab.List, {
                                                        children: [
                                                          JsxRuntime.jsx(React$1.Tab, {
                                                                children: "Example",
                                                                className: "cw-tab"
                                                              }),
                                                          JsxRuntime.jsx(React$1.Tab, {
                                                                children: "Code example",
                                                                className: "cw-tab"
                                                              }),
                                                          JsxRuntime.jsx(React$1.Tab, {
                                                                children: "API",
                                                                className: "cw-tab"
                                                              })
                                                        ],
                                                        className: "mb-4 cw-tab-list max-w-[500px] print:hidden"
                                                      }),
                                                  JsxRuntime.jsxs(React$1.Tab.Panels, {
                                                        children: [
                                                          JsxRuntime.jsx(React$1.Tab.Panel, {
                                                                children: param.jsModule
                                                              }),
                                                          JsxRuntime.jsx(React$1.Tab.Panel, {
                                                                children: JsxRuntime.jsx(Playground_CodeBlock.make, {
                                                                      code: param.codeExample
                                                                    })
                                                              }),
                                                          JsxRuntime.jsx(React$1.Tab.Panel, {
                                                                children: JsxRuntime.jsx(Playground_CodeBlock.make, {
                                                                      code: ""
                                                                    })
                                                              })
                                                        ]
                                                      })
                                                ]
                                              })
                                        });
                            }))
                    })
              ]
            });
}

var Details = {
  make: PlaygroundComponents$Details
};

export {
  List ,
  Details ,
}
/* react Not a pure module */
