

import * as Curry from "../../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Ui_Modal from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Modal.bs.js";
import * as Toolkit__Ui_Radio from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Radio.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as Toolkit__FormValidationFunctions from "../../../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/form/Toolkit__FormValidationFunctions.bs.js";
import * as AutocompleteAddress__ModalTextInput from "./AutocompleteAddress__ModalTextInput.bs.js";

function AutocompleteAddress__HouseNumberMissingModal(props) {
  var isVisible = props.isVisible;
  var onSubmit = props.onSubmit;
  var match = React.useState(function (param) {
        
      });
  var setSelectedAddressHasNoNumber = match[1];
  var selectedAddressHasNoNumber = match[0];
  var match$1 = React.useState(function (param) {
        return "";
      });
  var setHouseNumber = match$1[1];
  var houseNumber = match$1[0];
  var match$2 = React.useState(function (param) {
        return false;
      });
  var setHasError = match$2[1];
  var hasError = match$2[0];
  var intl = ReactIntl.useIntl();
  var fieldEmpty = function (value) {
    if (value === "") {
      if (hasError) {
        return intl.formatMessage(Toolkit__FormValidationFunctions.Msg.stringNonEmpty);
      } else if (hasError) {
        return Toolkit__FormValidationFunctions.requiredStrictPosInt(intl, value);
      } else {
        return ;
      }
    } else if (hasError) {
      return Toolkit__FormValidationFunctions.requiredStrictPosInt(intl, value);
    } else {
      return ;
    }
  };
  React.useEffect((function (param) {
          if (!isVisible) {
            Curry._1(setSelectedAddressHasNoNumber, (function (param) {
                    
                  }));
            Curry._1(setHouseNumber, (function (param) {
                    return "";
                  }));
            Curry._1(setHasError, (function (param) {
                    return false;
                  }));
          }
          
        }), [isVisible]);
  return JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
              isVisible: isVisible,
              title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_303a7409",
                        defaultMessage: "Confirmer"
                      })),
              body: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                  id: "_1938187e",
                                  defaultMessage: "Nous n'avons pas de numéro de rue pour cette adresse."
                                })
                          }),
                      JsxRuntime.jsx("strong", {
                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                  id: "_9385b2e8",
                                  defaultMessage: "Est-ce normal ?"
                                })
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Toolkit__Ui_Radio.make, {
                                    value: "yes",
                                    children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_24ace383",
                                              defaultMessage: "Oui"
                                            })),
                                    onChange: (function (param) {
                                        Curry._1(setSelectedAddressHasNoNumber, (function (param) {
                                                return true;
                                              }));
                                      }),
                                    name: "normal"
                                  }),
                              JsxRuntime.jsx(Toolkit__Ui_Radio.make, {
                                    value: "no",
                                    children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_6679e65c",
                                              defaultMessage: "Non"
                                            })),
                                    onChange: (function (param) {
                                        Curry._1(setSelectedAddressHasNoNumber, (function (param) {
                                                return false;
                                              }));
                                      }),
                                    name: "normal"
                                  })
                            ],
                            className: "flex flex-row gap-2 mt-4"
                          }),
                      Belt_Option.mapWithDefault(Belt_Option.flatMap(selectedAddressHasNoNumber, (function (selectedAddressHasNoNumber) {
                                  if (selectedAddressHasNoNumber) {
                                    return ;
                                  } else {
                                    return Caml_option.some(undefined);
                                  }
                                })), null, (function (param) {
                              return JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(AutocompleteAddress__ModalTextInput.make, {
                                                label: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_bd5b1167",
                                                      defaultMessage: "Quel est le numéro de rue ?"
                                                    }),
                                                id: "houseNumber",
                                                name: "houseNumber",
                                                value: houseNumber,
                                                placeholder: "1",
                                                error: fieldEmpty(houseNumber),
                                                onChange: (function ($$event) {
                                                    var value = $$event.target.value;
                                                    Curry._1(setHouseNumber, (function (param) {
                                                            return value;
                                                          }));
                                                  }),
                                                onBlur: (function (param) {
                                                    
                                                  }),
                                                inputClassName: "w-20",
                                                type_: "number"
                                              }),
                                          className: "mt-4"
                                        });
                            }))
                    ]
                  }),
              hide: props.hide,
              size: "sm",
              footer: Caml_option.some(JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                              color: "neutral",
                              onClick: (function (param) {
                                  if (selectedAddressHasNoNumber !== undefined) {
                                    if (selectedAddressHasNoNumber || houseNumber !== "") {
                                      return Curry._1(onSubmit, houseNumber);
                                    } else {
                                      return Curry._1(setHasError, (function (param) {
                                                    return true;
                                                  }));
                                    }
                                  } else {
                                    return Curry._1(setHasError, (function (param) {
                                                  return true;
                                                }));
                                  }
                                }),
                              type_: "button",
                              children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                        id: "_7494b947",
                                        defaultMessage: "Valider"
                                      }))
                            }),
                        className: "flex justify-end mt-4"
                      }))
            });
}

var make = AutocompleteAddress__HouseNumberMissingModal;

export {
  make ,
}
/* react Not a pure module */
