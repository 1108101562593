

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Js_math from "../../../../node_modules/rescript/lib/es6/js_math.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Hooks from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/hooks/Toolkit__Hooks.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as ApiComponents__HereMap from "../../src/components/ApiComponents__HereMap.bs.js";
import DataJson from "@root/playground/components/data.json";

function Playground_HereMap$InfoBubble(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__HereMap.make, {
                    mapOptions: {
                      zoom: 9,
                      center: {
                        lat: 50,
                        lng: 3
                      }
                    },
                    onLoad: (function (param) {
                        var ui = param.ui;
                        var group = new H.map.Group();
                        param.mapInstance.addObject(group);
                        group.addEventListener("tap", (function ($$event) {
                                var target = $$event.target;
                                var bubble = new H.ui.InfoBubble(target.getGeometry(), {
                                      content: target.getData()
                                    });
                                ui.addBubble(bubble);
                              }));
                        var marker = new H.map.Marker({
                              lat: 50,
                              lng: 3
                            }, {});
                        marker.setData("\n            <div style=\"width: 200px\">\n              <a href=\"https://www.mcfc.co.uk\" target=\"_blank\">Manchester City</a>\n            </div>\n            <div>\n              City of Manchester Stadium\n              <br />\n              Capacity: 55,097\n            </div>\n          ");
                        group.addObject(marker);
                      })
                  })
            });
}

var InfoBubble = {
  make: Playground_HereMap$InfoBubble
};

function Playground_HereMap$CirclesGroup(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__HereMap.make, {
                    mapOptions: {
                      zoom: 9,
                      center: {
                        lat: 43.9,
                        lng: -79.4
                      }
                    },
                    onLoad: (function (param) {
                        var ui = param.ui;
                        var mapInstance = param.mapInstance;
                        var group = new H.map.Group();
                        group.addEventListener("tap", (function ($$event) {
                                var bubble = new H.ui.InfoBubble($$event.target.getGeometry(), {
                                      content: $$event.target.getData()
                                    });
                                ui.addBubble(bubble);
                              }));
                        var clevelandCircle = new H.map.Circle(new H.geo.Point(41.4822, -81.6697), 11703, {
                              style: {
                                fillColor: "rgba(0, 255, 221, 0.66)"
                              }
                            });
                        var torontoCircle = new H.map.Circle(new H.geo.Point(43.7000, -79.4000), 75090, {
                              style: {
                                fillColor: "rgba(0, 255, 221, 0.66)"
                              }
                            });
                        var chicagoCircle = new H.map.Circle(new H.geo.Point(41.8369, -87.6847), 81570, {
                              style: {
                                fillColor: "rgba(0, 221, 255, 0.66)"
                              }
                            });
                        var newYorkCircle = new H.map.Circle(new H.geo.Point(40.7127, -74.0059), 252180, {
                              style: {
                                fillColor: "rgba(221, 0, 255, 0.66)"
                              }
                            });
                        clevelandCircle.setData({
                              maxZoom: 7
                            });
                        torontoCircle.setData({
                              maxZoom: 5
                            });
                        chicagoCircle.setData({
                              maxZoom: 5
                            });
                        newYorkCircle.setData({
                              maxZoom: 4
                            });
                        var container = new H.map.Group({
                              objects: Belt_Array.map([
                                    clevelandCircle,
                                    torontoCircle,
                                    chicagoCircle,
                                    newYorkCircle
                                  ], (function (prim) {
                                      return prim;
                                    }))
                            });
                        container.addEventListener("tap", (function (evt) {
                                var target = evt.target;
                                var match = target.getData();
                                mapInstance.getViewModel().setLookAtData({
                                      zoom: match.maxZoom,
                                      bounds: target.getBoundingBox()
                                    });
                              }));
                        mapInstance.addObject(container);
                        var marker = new H.map.Marker({
                              lat: 50,
                              lng: 3
                            }, {});
                        marker.setData("<div><a href=\"https://www.mcfc.co.uk\" target=\"_blank\">Manchester City</a></div>\n      <div>City of Manchester Stadium<br />Capacity: 55,097</div>");
                        group.addObject(marker);
                        mapInstance.addObject(group);
                      })
                  })
            });
}

var CirclesGroup = {
  make: Playground_HereMap$CirclesGroup
};

function Playground_HereMap$MarkerCluster(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__HereMap.make, {
                    mapOptions: {
                      zoom: 6,
                      center: {
                        lat: 50.426467222414374,
                        lng: 6.3054632497803595
                      }
                    },
                    onLoad: (function (param) {
                        var ui = param.ui;
                        var mapInstance = param.mapInstance;
                        var dataPoints = Belt_Array.map(DataJson, (function (item) {
                                return new H.clustering.DataPoint(item.latitude, item.longitude, null, item);
                              }));
                        var clusteredDataProvider = new H.clustering.Provider(dataPoints, {
                              clusteringOptions: {
                                eps: 64,
                                minWeight: 3
                              },
                              theme: {
                                getClusterPresentation: (function (cluster) {
                                    var points = [];
                                    cluster.forEachDataPoint(function (noisePoint) {
                                          points.push(noisePoint);
                                        });
                                    var randomDataPoint = Belt_Array.get(points, Js_math.random_int(0, points.length));
                                    var data = randomDataPoint.getData();
                                    var clusterMarker = new H.map.Marker(cluster.getPosition(), {
                                          icon: Caml_option.some(new H.map.Icon(data.thumbnail, {
                                                    size: {
                                                      w: 20,
                                                      h: 20
                                                    },
                                                    anchor: {
                                                      x: 10,
                                                      y: 10
                                                    }
                                                  })),
                                          min: cluster.getMinZoom(),
                                          max: cluster.getMaxZoom()
                                        });
                                    clusterMarker.setData(data);
                                    return clusterMarker;
                                  }),
                                getNoisePresentation: (function (noisePoint) {
                                    var data = noisePoint.getData();
                                    var noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
                                          icon: Caml_option.some(new H.map.Icon(data.thumbnail, {
                                                    size: {
                                                      w: 20,
                                                      h: 20
                                                    },
                                                    anchor: {
                                                      x: 10,
                                                      y: 10
                                                    }
                                                  })),
                                          min: noisePoint.getMinZoom()
                                        });
                                    noiseMarker.setData(data);
                                    return noiseMarker;
                                  })
                              }
                            });
                        var bubbleInstance = {
                          contents: undefined
                        };
                        clusteredDataProvider.addEventListener("tap", (function ($$event) {
                                var target = $$event.target;
                                var position = target.getGeometry();
                                var data = target.getData();
                                var bubbleContent = "\n              <div class=\"bubble\">\n          <a class=\"bubble-image\" style=\"background-image: url(" + data.fullurl + ")\" href=\"" + data.url + "\" target=\"_blank\"></a>\n          <span>\n            Photo by:\n                    <a href=\"//commons.wikimedia.org/wiki/User:" + encodeURIComponent(Belt_Option.getWithDefault(data.author, "")) + "\" target=\"_blank\">\n            " + Belt_Option.getWithDefault(data.author, "") + "</a>\n        <hr/>\n        <a class=\"bubble-footer\" href=\"//commons.wikimedia.org/\" target=\"_blank\">\n          <span class=\"bubble-desc\">\n          Photos provided by Wikimedia Commons are <br/>under the copyright of their owners.\n          </span>\n        </a>\n      </span>\n    </div>\n              ";
                                var bubble = bubbleInstance.contents;
                                if (bubble !== undefined) {
                                  var bubble$1 = Caml_option.valFromOption(bubble);
                                  bubble$1.setPosition(position);
                                  bubble$1.setContent(bubbleContent);
                                  bubble$1.open();
                                } else {
                                  var bubble$2 = new H.ui.InfoBubble(position, {
                                        content: bubbleContent
                                      });
                                  ui.addBubble(bubble$2);
                                  bubbleInstance.contents = Caml_option.some(bubble$2);
                                }
                                mapInstance.setCenter(position, true);
                              }));
                        var layer = new H.map.layer.ObjectLayer(clusteredDataProvider);
                        mapInstance.addLayer(layer);
                      }),
                    className: "!h-[400px]",
                    useClustering: true
                  })
            });
}

var MarkerCluster = {
  make: Playground_HereMap$MarkerCluster
};

function Playground_HereMap$ServiceRoute(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(ApiComponents__HereMap.make, {
                    mapOptions: {
                      zoom: 13,
                      center: {
                        lat: 52.5160,
                        lng: 13.3779
                      }
                    },
                    onLoad: (function (param) {
                        var mapInstance = param.mapInstance;
                        var router = param.platform.getRoutingService(null, 8);
                        router.calculateRoute({
                              routingMode: "fast",
                              transportMode: "car",
                              origin: "48.86,2.31",
                              destination: "48.86,2.35",
                              return: "polyline",
                              via: Caml_option.some(new H.service.Url.MultiValueQueryParameter([
                                        "48.8664,2.3234",
                                        "48.8703,2.3499"
                                      ]))
                            }, (function (result) {
                                var route = Belt_Array.get(result.routes, 0);
                                Belt_Array.forEach(route.sections, (function (section) {
                                        var linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
                                        var polyline = new H.map.Polyline(linestring, {
                                              style: {
                                                lineWidth: 4,
                                                strokeColor: "rgba(0, 128, 255, 0.7)"
                                              }
                                            });
                                        mapInstance.addObject(polyline);
                                        mapInstance.getViewModel().setLookAtData({
                                              bounds: polyline.getBoundingBox()
                                            });
                                      }));
                              }), (function (error) {
                                console.log("calculateRoute error");
                                console.log(error);
                              }));
                      }),
                    className: "!h-[400px]"
                  })
            });
}

var ServiceRoute = {
  make: Playground_HereMap$ServiceRoute
};

function Playground_HereMap(props) {
  var circleDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var markerClusterDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var infoBubbleDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var routeServiceDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Simple marker with info bubble",
                                      className: "text-lg font-semibold"
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                      onClick: (function (param) {
                                          Curry._1(infoBubbleDisclosure.toggle, undefined);
                                        }),
                                      children: "Toggle map"
                                    })
                              ],
                              className: "flex flex-row items-center gap-3"
                            }),
                        infoBubbleDisclosure.isOpen ? JsxRuntime.jsx(Playground_HereMap$InfoBubble, {}) : null
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Circles group",
                                      className: "text-lg font-semibold"
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                      onClick: (function (param) {
                                          Curry._1(circleDisclosure.toggle, undefined);
                                        }),
                                      children: "Toggle map"
                                    })
                              ],
                              className: "flex flex-row items-center gap-3"
                            }),
                        circleDisclosure.isOpen ? JsxRuntime.jsx(Playground_HereMap$CirclesGroup, {}) : null
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "MarkerCluster custom",
                                      className: "text-lg font-semibold"
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                      onClick: (function (param) {
                                          Curry._1(markerClusterDisclosure.toggle, undefined);
                                        }),
                                      children: "Toggle map"
                                    })
                              ],
                              className: "flex flex-row items-center gap-3"
                            }),
                        markerClusterDisclosure.isOpen ? JsxRuntime.jsx(Playground_HereMap$MarkerCluster, {}) : null
                      ]
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("header", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Route service",
                                      className: "text-lg font-semibold"
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                      onClick: (function (param) {
                                          Curry._1(routeServiceDisclosure.toggle, undefined);
                                        }),
                                      children: "Toggle map"
                                    })
                              ],
                              className: "flex flex-row items-center gap-3"
                            }),
                        routeServiceDisclosure.isOpen ? JsxRuntime.jsx(Playground_HereMap$ServiceRoute, {}) : null
                      ]
                    })
              ],
              className: "flex flex-col gap-4"
            });
}

var make = Playground_HereMap;

export {
  InfoBubble ,
  CirclesGroup ,
  MarkerCluster ,
  ServiceRoute ,
  make ,
}
/* Toolkit__Hooks Not a pure module */
