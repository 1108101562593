


var isLocal = import.meta.env.VITE_ENV === "local";

var isTesting = import.meta.env.VITE_ENV === "testing";

var isStaging = import.meta.env.VITE_ENV === "staging";

var isProduction = import.meta.env.VITE_ENV === "production";

var isNodeProduction = import.meta.env.NODE_ENV === "production";

var isNodeDevelopment = import.meta.env.NODE_ENV === "development";

var env = import.meta.env.VITE_ENV;

var nodeEnv = import.meta.env.NODE_ENV;

var authApiUrl = import.meta.env.VITE_AUTH_API_URL;

var v5ApiUrl = import.meta.env.VITE_V5_API_URL;

var c1ApiUrl = import.meta.env.VITE_C1_API_URL;

var unleashToken = import.meta.env.VITE_UNLEASH_TOKEN;

var unleashUrl = import.meta.env.VITE_UNLEASH_URL;

var gmapKey = import.meta.env.VITE_GMAP_KEY;

var v6ApiUrl = import.meta.env.VITE_V6_API_URL;

var basePath = import.meta.env.VITE_BASE_PATH;

var legacyUrl = import.meta.env.VITE_LEGACY_URL;

var boLoginUrl = import.meta.env.VITE_BO_LOGIN_URL;

var boAdminUrl = import.meta.env.VITE_BO_ADMIN_URL;

var boTransporterUrl = import.meta.env.VITE_BO_TRANSPORTER_URL;

var boClientUrl = import.meta.env.VITE_BO_CLIENT_URL;

var boStoreUrl = import.meta.env.VITE_BO_STORE_URL;

var boHubUrl = import.meta.env.VITE_BO_HUB_URL;

var datadogSampleRate = import.meta.env.VITE_DATADOG_SAMPLE_RATE;

var hereKey = import.meta.env.VITE_HERE_KEY;

export {
  env ,
  isLocal ,
  isTesting ,
  isStaging ,
  isProduction ,
  nodeEnv ,
  isNodeProduction ,
  isNodeDevelopment ,
  authApiUrl ,
  v5ApiUrl ,
  c1ApiUrl ,
  unleashToken ,
  unleashUrl ,
  gmapKey ,
  v6ApiUrl ,
  basePath ,
  legacyUrl ,
  boLoginUrl ,
  boAdminUrl ,
  boTransporterUrl ,
  boClientUrl ,
  boStoreUrl ,
  boHubUrl ,
  datadogSampleRate ,
  hereKey ,
}
/* isLocal Not a pure module */
