

import * as Toolkit__Router from "../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/router/Toolkit__Router.bs.js";

function make(url) {
  var path = url.path;
  var path$1;
  if (path && path.hd === "colisweb-open-source") {
    var match = path.tl;
    path$1 = match && match.hd === "rescript-toolkit" ? match.tl : path;
  } else {
    path$1 = path;
  }
  if (!path$1) {
    return "Home";
  }
  if (path$1.hd !== "components") {
    return "NotFound";
  }
  var rest = path$1.tl;
  if (rest) {
    if (rest.tl) {
      return "NotFound";
    } else {
      return {
              TAG: "Components",
              _0: {
                TAG: "Details",
                _0: rest.hd
              }
            };
    }
  } else {
    return {
            TAG: "Components",
            _0: "List"
          };
  }
}

function toString(route) {
  if (typeof route !== "object") {
    if (route === "Home") {
      return import.meta.env.BASE_URL;
    } else {
      return import.meta.env.BASE_URL + "404";
    }
  }
  var route$1 = route._0;
  var base = import.meta.env.BASE_URL + "components";
  if (typeof route$1 !== "object") {
    return base;
  } else {
    return base + "/" + route$1._0;
  }
}

var RouterConfig = {
  make: make,
  toString: toString
};

var include = Toolkit__Router.Make(RouterConfig);

var replace = include.replace;

var routerContext = include.routerContext;

var RouterContextProvider = include.RouterContextProvider;

var Provider = include.Provider;

var useRoute = include.useRoute;

var isRouteEqual = include.isRouteEqual;

var useIsCurrentRoute = include.useIsCurrentRoute;

var navigate = include.navigate;

var redirect = include.redirect;

var toString$1 = include.toString;

var usePreviousLinkWithDefault = include.usePreviousLinkWithDefault;

var Link = include.Link;

var Redirect = include.Redirect;

var RedirectExternal = include.RedirectExternal;

var Breadcrumb = include.Breadcrumb;

var Divider = include.Divider;

var commonClassName = include.commonClassName;

var SingleLink = include.SingleLink;

var GroupedLinks = include.GroupedLinks;

export {
  RouterConfig ,
  replace ,
  routerContext ,
  RouterContextProvider ,
  Provider ,
  useRoute ,
  isRouteEqual ,
  useIsCurrentRoute ,
  navigate ,
  redirect ,
  toString$1 as toString,
  usePreviousLinkWithDefault ,
  Link ,
  Redirect ,
  RedirectExternal ,
  Breadcrumb ,
  Divider ,
  commonClassName ,
  SingleLink ,
  GroupedLinks ,
}
/* include Not a pure module */
