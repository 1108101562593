

import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__ParcelDamageStatusTag from "../../src/components/parcel/damage-status-tag/ApiComponents__ParcelDamageStatusTag.bs.js";

function Playground_ParcelDamageStatusTag(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__ParcelDamageStatusTag.make, {
                      damageStatus: "damagedButDeliverable"
                    }),
                JsxRuntime.jsx(ApiComponents__ParcelDamageStatusTag.make, {
                      damageStatus: "undeliverable"
                    }),
                JsxRuntime.jsx(ApiComponents__ParcelDamageStatusTag.make, {
                      damageStatus: "notDamaged"
                    })
              ],
              className: "flex flex-col gap-6 bg-white p-4"
            });
}

var make = Playground_ParcelDamageStatusTag;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
