

import * as JsxRuntime from "react/jsx-runtime";
import * as ApiComponents__ParcelDirectiveTag from "../../src/components/parcel/directive-tag/ApiComponents__ParcelDirectiveTag.bs.js";

function Playground_ParcelDirectiveTag(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ApiComponents__ParcelDirectiveTag.make, {
                      directive: "toDeliver"
                    }),
                JsxRuntime.jsx(ApiComponents__ParcelDirectiveTag.make, {
                      directive: "toThrow"
                    }),
                JsxRuntime.jsx(ApiComponents__ParcelDirectiveTag.make, {
                      directive: "toReturn"
                    }),
                JsxRuntime.jsx(ApiComponents__ParcelDirectiveTag.make, {
                      directive: "toDecide"
                    })
              ],
              className: "flex flex-col gap-6 bg-white p-4"
            });
}

var make = Playground_ParcelDirectiveTag;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
