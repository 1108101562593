


var probablyWithoutLift = {
  id: "_17a2eae8",
  defaultMessage: "Indéterminé"
};

var withLift = {
  id: "_f47d421f",
  defaultMessage: "Avec ascenseur"
};

var withoutLift = {
  id: "_e589dea1",
  defaultMessage: "Sans ascenseur"
};

function humanize(intl, value) {
  switch (value) {
    case "maybe_lift" :
        return intl.formatMessage(probablyWithoutLift);
    case "with_lift" :
        return intl.formatMessage(withLift);
    case "no_lift" :
        return intl.formatMessage(withoutLift);
    
  }
}

var Lift = {
  probablyWithoutLift: probablyWithoutLift,
  withLift: withLift,
  withoutLift: withoutLift,
  humanize: humanize
};

var inRoom = {
  id: "_d9b961bd",
  defaultMessage: "Livraison dans la pièce du choix"
};

var sidewalk = {
  id: "_96e0e82d",
  defaultMessage: "Livraison au pied du camion"
};

var trailed = {
  id: "_01f75e3b",
  defaultMessage: "Livraison tractée"
};

function humanize$1(intl, value) {
  switch (value) {
    case "sidewalkdelivery" :
        return intl.formatMessage(sidewalk);
    case "roomdelivery" :
        return intl.formatMessage(inRoom);
    case "traileddelivery" :
        return intl.formatMessage(trailed);
    
  }
}

var HeftingOption = {
  inRoom: inRoom,
  sidewalk: sidewalk,
  trailed: trailed,
  humanize: humanize$1
};

var clientRequest = {
  id: "_078eca27",
  defaultMessage: "Requête client"
};

var storeRequest = {
  id: "_d1ea1839",
  defaultMessage: "Requête magasin"
};

var customerMissedSms = {
  id: "_b687b02e",
  defaultMessage: "Le client n’a pas vu son SMS"
};

var lateTraction = {
  id: "_9eb23b61",
  defaultMessage: "Commande non présente : Retard traction client"
};

var clientWorkflowError = {
  id: "_1f47e500",
  defaultMessage: "Commande non présente : Erreur flux IT"
};

var missingOrder = {
  id: "_7afe2576",
  defaultMessage: "Commande incomplète"
};

var transporterLate = {
  id: "_842f58b1",
  defaultMessage: "Transporteur en retard"
};

var lackOfCapacity = {
  id: "_c7bc1d12",
  defaultMessage: "Manque de capacité"
};

var internalWorkflowError = {
  id: "_2302487b",
  defaultMessage: "Problème IT Colisweb"
};

var internalLateTraction = {
  id: "_557a4d63",
  defaultMessage: "Commande non présente : Retard traction Colisweb"
};

var other = {
  id: "_f87bd32c",
  defaultMessage: "Force majeure (Neige, route bloquée…)"
};

var inaccessibleLez = {
  id: "_50995c87",
  defaultMessage: "Accès ZFE impossible"
};

var refined = {
  id: "_681bb95d",
  defaultMessage: "Affiné automatiquement"
};

function humanize$2(intl, value) {
  switch (value) {
    case "customer_missed_sms" :
        return intl.formatMessage(customerMissedSms);
    case "client_request" :
        return intl.formatMessage(clientRequest);
    case "store_request" :
        return intl.formatMessage(storeRequest);
    case "late_traction" :
        return intl.formatMessage(lateTraction);
    case "client_workflow_error" :
        return intl.formatMessage(clientWorkflowError);
    case "missing_order" :
        return intl.formatMessage(missingOrder);
    case "transporter_late" :
        return intl.formatMessage(transporterLate);
    case "lack_of_capacity" :
        return intl.formatMessage(lackOfCapacity);
    case "internal_workflow_error" :
        return intl.formatMessage(internalWorkflowError);
    case "internal_late_traction" :
        return intl.formatMessage(internalLateTraction);
    case "other" :
        return intl.formatMessage(other);
    case "inaccessible_lez" :
        return intl.formatMessage(inaccessibleLez);
    case "refined" :
        return intl.formatMessage(refined);
    
  }
}

var ModificationReason = {
  clientRequest: clientRequest,
  storeRequest: storeRequest,
  customerMissedSms: customerMissedSms,
  lateTraction: lateTraction,
  clientWorkflowError: clientWorkflowError,
  missingOrder: missingOrder,
  transporterLate: transporterLate,
  lackOfCapacity: lackOfCapacity,
  internalWorkflowError: internalWorkflowError,
  internalLateTraction: internalLateTraction,
  other: other,
  inaccessibleLez: inaccessibleLez,
  refined: refined,
  humanize: humanize$2
};

var admin = {
  id: "_0ae8a9db",
  defaultMessage: "Colisweb"
};

var storeAdmin = {
  id: "_11f22d27",
  defaultMessage: "Admin magasin"
};

var clientAdmin = {
  id: "_4f9ad7ba",
  defaultMessage: "Admin client"
};

var transporterAdmin = {
  id: "_6276ecd6",
  defaultMessage: "Admin transporteur"
};

var eRetailer = {
  id: "_b7434c82",
  defaultMessage: "e-retailer"
};

var carrier = {
  id: "_00b09242",
  defaultMessage: "Coursier"
};

var transporterPoleAdmin = {
  id: "_3db2aa74",
  defaultMessage: "Admin pôle transporteur"
};

var customer = {
  id: "_577d7068",
  defaultMessage: "Client"
};

var supplier = {
  id: "_73d163bf",
  defaultMessage: "Fournisseur"
};

function humanize$3(intl, value) {
  switch (value) {
    case "client_admin" :
        return intl.formatMessage(clientAdmin);
    case "store_admin" :
        return intl.formatMessage(storeAdmin);
    case "transporter_admin" :
        return intl.formatMessage(transporterAdmin);
    case "e_retailer" :
        return intl.formatMessage(eRetailer);
    case "carrier" :
        return intl.formatMessage(carrier);
    case "transporter_pole_admin" :
        return intl.formatMessage(transporterPoleAdmin);
    case "customer" :
        return intl.formatMessage(customer);
    case "supplier" :
        return intl.formatMessage(supplier);
    default:
      return intl.formatMessage(admin);
  }
}

var UserRole = {
  admin: admin,
  storeAdmin: storeAdmin,
  clientAdmin: clientAdmin,
  transporterAdmin: transporterAdmin,
  eRetailer: eRetailer,
  carrier: carrier,
  transporterPoleAdmin: transporterPoleAdmin,
  customer: customer,
  supplier: supplier,
  humanize: humanize$3
};

var line1 = {
  id: "_50d46c2f",
  defaultMessage: "Adresse"
};

var line2 = {
  id: "_f434c67a",
  defaultMessage: "Complément d'adresse"
};

var city = {
  id: "_92e2da12",
  defaultMessage: "Ville"
};

var country = {
  id: "_6c51308c",
  defaultMessage: "Pays"
};

var startsAt = {
  id: "_a6118c29",
  defaultMessage: "Début du créneau"
};

var endsAt = {
  id: "_8a927c31",
  defaultMessage: "Fin du créneau"
};

var floor = {
  id: "_9d92c83a",
  defaultMessage: "Étage"
};

var society = {
  id: "_7efee54d",
  defaultMessage: "Société"
};

var additionalInformations = {
  id: "_d8339331",
  defaultMessage: "Informations complémentaires"
};

var liftInformation = {
  id: "_78bb3039",
  defaultMessage: "Ascenseur"
};

var stateOrProvince = {
  id: "_8e596978",
  defaultMessage: "Pays ou province"
};

var postalCode = {
  id: "_db17b356",
  defaultMessage: "Code postal"
};

var administrativeAreaCode2 = {
  id: "_fe647cd8",
  defaultMessage: "Code zone administrative 2"
};

var administrativeAreaCode3 = {
  id: "_50aa5267",
  defaultMessage: "Code zone administrative 3"
};

function humanize$4(intl, value) {
  switch (value) {
    case "line1" :
        return intl.formatMessage(line1);
    case "line2" :
        return intl.formatMessage(line2);
    case "city" :
        return intl.formatMessage(city);
    case "country" :
        return intl.formatMessage(country);
    case "society" :
        return intl.formatMessage(society);
    case "floor" :
        return intl.formatMessage(floor);
    case "state_or_province" :
        return intl.formatMessage(stateOrProvince);
    case "postal_code" :
        return intl.formatMessage(postalCode);
    case "starts_at" :
        return intl.formatMessage(startsAt);
    case "ends_at" :
        return intl.formatMessage(endsAt);
    case "lift_information" :
        return intl.formatMessage(liftInformation);
    case "additional_informations" :
        return intl.formatMessage(additionalInformations);
    case "administrative_area_code2" :
        return intl.formatMessage(administrativeAreaCode2);
    case "administrative_area_code3" :
        return intl.formatMessage(administrativeAreaCode3);
    
  }
}

var GetChangesHistoryField = {
  line1: line1,
  line2: line2,
  city: city,
  country: country,
  startsAt: startsAt,
  endsAt: endsAt,
  floor: floor,
  society: society,
  additionalInformations: additionalInformations,
  liftInformation: liftInformation,
  stateOrProvince: stateOrProvince,
  postalCode: postalCode,
  administrativeAreaCode2: administrativeAreaCode2,
  administrativeAreaCode3: administrativeAreaCode3,
  humanize: humanize$4
};

var destinationAddress = {
  id: "_703ca8d0",
  defaultMessage: "Adresse de destination"
};

var slot = {
  id: "_41d13330",
  defaultMessage: "Créneau"
};

var originAddress = {
  id: "_9edf0485",
  defaultMessage: "Adresse donneur d'ordre"
};

function humanize$5(intl, value) {
  switch (value) {
    case "destination_address" :
        return intl.formatMessage(destinationAddress);
    case "origin_address" :
        return intl.formatMessage(originAddress);
    case "slot" :
        return intl.formatMessage(slot);
    
  }
}

var GetChangesHistoryType = {
  destinationAddress: destinationAddress,
  slot: slot,
  originAddress: originAddress,
  humanize: humanize$5
};

var line1$1 = {
  id: "_50d46c2f",
  defaultMessage: "Adresse"
};

var line2$1 = {
  id: "_f434c67a",
  defaultMessage: "Complément d'adresse"
};

var city$1 = {
  id: "_92e2da12",
  defaultMessage: "Ville"
};

var country$1 = {
  id: "_6c51308c",
  defaultMessage: "Pays"
};

var startsAt$1 = {
  id: "_a6118c29",
  defaultMessage: "Début du créneau"
};

var endsAt$1 = {
  id: "_8a927c31",
  defaultMessage: "Fin du créneau"
};

var floor$1 = {
  id: "_9d92c83a",
  defaultMessage: "Étage"
};

var society$1 = {
  id: "_7efee54d",
  defaultMessage: "Société"
};

var additionalInformations$1 = {
  id: "_d8339331",
  defaultMessage: "Informations complémentaires"
};

var liftInformation$1 = {
  id: "_78bb3039",
  defaultMessage: "Ascenseur"
};

var stateOrProvince$1 = {
  id: "_8e596978",
  defaultMessage: "Pays ou province"
};

var postalCode$1 = {
  id: "_db17b356",
  defaultMessage: "Code postal"
};

var administrativeAreaCode2$1 = {
  id: "_fe647cd8",
  defaultMessage: "Code zone administrative 2"
};

var administrativeAreaCode3$1 = {
  id: "_50aa5267",
  defaultMessage: "Code zone administrative 3"
};

function humanize$6(intl, x) {
  switch (x) {
    case "line1" :
        return intl.formatMessage(line1$1);
    case "line2" :
        return intl.formatMessage(line2$1);
    case "city" :
        return intl.formatMessage(city$1);
    case "country" :
        return intl.formatMessage(country$1);
    case "society" :
        return intl.formatMessage(society$1);
    case "floor" :
        return intl.formatMessage(floor$1);
    case "state_or_province" :
        return intl.formatMessage(stateOrProvince$1);
    case "postal_code" :
        return intl.formatMessage(postalCode$1);
    case "starts_at" :
        return intl.formatMessage(startsAt$1);
    case "ends_at" :
        return intl.formatMessage(endsAt$1);
    case "lift_information" :
        return intl.formatMessage(liftInformation$1);
    case "additional_informations" :
        return intl.formatMessage(additionalInformations$1);
    case "administrative_area_code2" :
        return intl.formatMessage(administrativeAreaCode2$1);
    case "administrative_area_code3" :
        return intl.formatMessage(administrativeAreaCode3$1);
    
  }
}

var ModificationHistoryField = {
  line1: line1$1,
  line2: line2$1,
  city: city$1,
  country: country$1,
  startsAt: startsAt$1,
  endsAt: endsAt$1,
  floor: floor$1,
  society: society$1,
  additionalInformations: additionalInformations$1,
  liftInformation: liftInformation$1,
  stateOrProvince: stateOrProvince$1,
  postalCode: postalCode$1,
  administrativeAreaCode2: administrativeAreaCode2$1,
  administrativeAreaCode3: administrativeAreaCode3$1,
  humanize: humanize$6
};

var confirmed = {
  id: "_b02c794a",
  defaultMessage: "Confirmée"
};

var arrivalAtPickupPoint = {
  id: "_f8d31c96",
  defaultMessage: "Arrivée au point de retrait"
};

var arrivalAtShippingPoint = {
  id: "_1c586fa5",
  defaultMessage: "Arrivée au point de livraison"
};

var packageWithdrawn = {
  id: "_dd569a2d",
  defaultMessage: "Retirée"
};

var packageWithdrawalPbOk = {
  id: "_0d9d1e6e",
  defaultMessage: "Retirée avec réserve(s)"
};

var deliveryFailed = {
  id: "_ce941951",
  defaultMessage: "Echec livraison"
};

var delivered = {
  id: "_8f9d883c",
  defaultMessage: "Livrée"
};

var packageWithdrawalFailed = {
  id: "_f5ca61a0",
  defaultMessage: "Echec retrait"
};

var deliveryPbOk = {
  id: "_3c018d3f",
  defaultMessage: "Livrée avec réserve(s)"
};

var packageReturned = {
  id: "_7f61bb8c",
  defaultMessage: "Retournée"
};

var packageReturnFailed = {
  id: "_cd4abc2d",
  defaultMessage: "Echec retour"
};

var canceled = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

var nonDeliverable = {
  id: "_2e93222b",
  defaultMessage: "Non-livrable"
};

function humanize$7(intl, x) {
  switch (x) {
    case "confirmed" :
        return intl.formatMessage(confirmed);
    case "arrival_at_pickup_point" :
        return intl.formatMessage(arrivalAtPickupPoint);
    case "arrival_at_shipping_point" :
        return intl.formatMessage(arrivalAtShippingPoint);
    case "package_withdrawn" :
        return intl.formatMessage(packageWithdrawn);
    case "package_withdrawal_pb_ok" :
        return intl.formatMessage(packageWithdrawalPbOk);
    case "delivery_failed" :
        return intl.formatMessage(deliveryFailed);
    case "delivered" :
        return intl.formatMessage(delivered);
    case "package_withdrawal_failed" :
        return intl.formatMessage(packageWithdrawalFailed);
    case "delivery_pb_ok" :
        return intl.formatMessage(deliveryPbOk);
    case "package_returned" :
        return intl.formatMessage(packageReturned);
    case "package_return_failed" :
        return intl.formatMessage(packageReturnFailed);
    case "non_deliverable" :
        return intl.formatMessage(nonDeliverable);
    case "canceled" :
        return intl.formatMessage(canceled);
    
  }
}

var FullStatusProvider = {
  confirmed: confirmed,
  arrivalAtPickupPoint: arrivalAtPickupPoint,
  arrivalAtShippingPoint: arrivalAtShippingPoint,
  packageWithdrawn: packageWithdrawn,
  packageWithdrawalPbOk: packageWithdrawalPbOk,
  deliveryFailed: deliveryFailed,
  delivered: delivered,
  packageWithdrawalFailed: packageWithdrawalFailed,
  deliveryPbOk: deliveryPbOk,
  packageReturned: packageReturned,
  packageReturnFailed: packageReturnFailed,
  canceled: canceled,
  nonDeliverable: nonDeliverable,
  humanize: humanize$7
};

var destinationAddress$1 = {
  id: "_703ca8d0",
  defaultMessage: "Adresse de destination"
};

var slot$1 = {
  id: "_41d13330",
  defaultMessage: "Créneau"
};

var originAddress$1 = {
  id: "_494690c0",
  defaultMessage: "Adresse de retrait"
};

function humanize$8(intl, x) {
  switch (x) {
    case "destination_address" :
        return intl.formatMessage(destinationAddress$1);
    case "slot" :
        return intl.formatMessage(slot$1);
    case "origin_address" :
        return intl.formatMessage(originAddress$1);
    
  }
}

var ModificationHistoryType = {
  destinationAddress: destinationAddress$1,
  slot: slot$1,
  originAddress: originAddress$1,
  humanize: humanize$8
};

var other$1 = {
  id: "_fc0c78f1",
  defaultMessage: "Autre problème"
};

var noPackage = {
  id: "_cb3bbdfc",
  defaultMessage: "Aucun colis à retirer"
};

var tooMuchPackages = {
  id: "_54573fd0",
  defaultMessage: "Trop de colis"
};

var tooManyPackages = {
  id: "_54573fd0",
  defaultMessage: "Trop de colis"
};

var missingPackages = {
  id: "_cc9a02db",
  defaultMessage: "Colis manquants"
};

var tooHeavy = {
  id: "_f5d7f470",
  defaultMessage: "Colis trop lourd"
};

var wrongDimensions = {
  id: "_a3f00218",
  defaultMessage: "Mauvaise dimensions"
};

var pickupPointClosed = {
  id: "_16b63caa",
  defaultMessage: "Point de retrait fermé"
};

var absentCustomer = {
  id: "_2f11f441",
  defaultMessage: "Client absent"
};

var unreachableCustomer = {
  id: "_5991d5b3",
  defaultMessage: "Client injoignable"
};

var wrongAddress = {
  id: "_04fdfae1",
  defaultMessage: "Mauvaise adresse"
};

var deliveryRejection = {
  id: "_b3fadabb",
  defaultMessage: "Refus de réception"
};

var wrongPackage = {
  id: "_5606fc22",
  defaultMessage: "Mauvais colis"
};

var packageInformationsError = {
  id: "_fc0c78f1",
  defaultMessage: "Autre problème"
};

var waiting = {
  id: "_a3bcfe6e",
  defaultMessage: "Attente"
};

var damagedPackages = {
  id: "_361410f3",
  defaultMessage: "Colis endommagés"
};

var unavailableCommand = {
  id: "_2ee73087",
  defaultMessage: "Commande indisponible"
};

var packagingMissing = {
  id: "_5ba19e9f",
  defaultMessage: "Colis manquant(s)"
};

var delay = {
  id: "_884c60d4",
  defaultMessage: "Retard"
};

var unavailableRecipient = {
  id: "_c3d028a9",
  defaultMessage: "Destinataire indisponible"
};

var nonCompliantPackages = {
  id: "_5145ffec",
  defaultMessage: "Colis non conforme(s)"
};

var customerRefusal = {
  id: "_9a01813f",
  defaultMessage: "Le client a refusé le colis"
};

var inadapatedVehicle = {
  id: "_fd60123e",
  defaultMessage: "Véhicule inadapté"
};

var handlingNotPossible = {
  id: "_b4f8b6ad",
  defaultMessage: "Manutention impossible"
};

var closedPickupPoint = {
  id: "_c088190f",
  defaultMessage: "Lieu de chargement inaccessible"
};

var wrongPickupAddress = {
  id: "_8684b10f",
  defaultMessage: "Mauvaise adresse de chargement"
};

var insufficientPackaging = {
  id: "_f2f2e500",
  defaultMessage: "Emballage(s) insuffisant(s)"
};

var nonAvailableRecipient = {
  id: "_c3d028a9",
  defaultMessage: "Destinataire indisponible"
};

var otherPrestationAsked = {
  id: "_937cfdc3",
  defaultMessage: "Autre(s) prestation(s) demandée(s)"
};

var deliveryImpossible = {
  id: "_1ec06c80",
  defaultMessage: "Livraison impossible"
};

var wrongShippingAddress = {
  id: "_43092f29",
  defaultMessage: "Adresse erronée"
};

var recoveryRefusal = {
  id: "_21c60202",
  defaultMessage: "Refus de reprise"
};

var missingPackets = {
  id: "_5ba19e9f",
  defaultMessage: "Colis manquant(s)"
};

function humanize$9(intl, x) {
  var msg;
  switch (x) {
    case "no_package" :
        msg = noPackage;
        break;
    case "too_much_packages" :
        msg = tooMuchPackages;
        break;
    case "too_many_packages" :
        msg = tooManyPackages;
        break;
    case "missing_packages" :
        msg = missingPackages;
        break;
    case "too_heavy" :
        msg = tooHeavy;
        break;
    case "wrong_dimensions" :
        msg = wrongDimensions;
        break;
    case "pickup_point_closed" :
        msg = pickupPointClosed;
        break;
    case "absent_customer" :
        msg = absentCustomer;
        break;
    case "unreachable_customer" :
        msg = unreachableCustomer;
        break;
    case "wrong_address" :
        msg = wrongAddress;
        break;
    case "delivery_rejection" :
        msg = deliveryRejection;
        break;
    case "wrong_package" :
        msg = wrongPackage;
        break;
    case "package_informations_error" :
        msg = packageInformationsError;
        break;
    case "delay" :
        msg = delay;
        break;
    case "unavailable_recipient" :
        msg = unavailableRecipient;
        break;
    case "customer_refusal" :
        msg = customerRefusal;
        break;
    case "other" :
        msg = other$1;
        break;
    case "inadapated_vehicle" :
        msg = inadapatedVehicle;
        break;
    case "handling_not_possible" :
        msg = handlingNotPossible;
        break;
    case "unavailable_command" :
    case "unavailable_order" :
        msg = unavailableCommand;
        break;
    case "waiting" :
        msg = waiting;
        break;
    case "closed_pickup_point" :
        msg = closedPickupPoint;
        break;
    case "packaging_missing" :
        msg = packagingMissing;
        break;
    case "missing_packets" :
        msg = missingPackets;
        break;
    case "wrong_pickup_address" :
        msg = wrongPickupAddress;
        break;
    case "insufficient_packaging" :
        msg = insufficientPackaging;
        break;
    case "non_available_recipient" :
        msg = nonAvailableRecipient;
        break;
    case "damaged_packages" :
        msg = damagedPackages;
        break;
    case "non_compliant_packages" :
        msg = nonCompliantPackages;
        break;
    case "other_prestation_asked" :
        msg = otherPrestationAsked;
        break;
    case "delivery_impossible" :
        msg = deliveryImpossible;
        break;
    case "wrong_shipping_address" :
        msg = wrongShippingAddress;
        break;
    case "recovery_refusal" :
        msg = recoveryRefusal;
        break;
    
  }
  return intl.formatMessage(msg);
}

var TransitionProblem = {
  other: other$1,
  noPackage: noPackage,
  tooMuchPackages: tooMuchPackages,
  tooManyPackages: tooManyPackages,
  missingPackages: missingPackages,
  tooHeavy: tooHeavy,
  wrongDimensions: wrongDimensions,
  pickupPointClosed: pickupPointClosed,
  absentCustomer: absentCustomer,
  unreachableCustomer: unreachableCustomer,
  wrongAddress: wrongAddress,
  deliveryRejection: deliveryRejection,
  wrongPackage: wrongPackage,
  packageInformationsError: packageInformationsError,
  waiting: waiting,
  damagedPackages: damagedPackages,
  unavailableCommand: unavailableCommand,
  packagingMissing: packagingMissing,
  delay: delay,
  unavailableRecipient: unavailableRecipient,
  nonCompliantPackages: nonCompliantPackages,
  customerRefusal: customerRefusal,
  inadapatedVehicle: inadapatedVehicle,
  handlingNotPossible: handlingNotPossible,
  closedPickupPoint: closedPickupPoint,
  wrongPickupAddress: wrongPickupAddress,
  insufficientPackaging: insufficientPackaging,
  nonAvailableRecipient: nonAvailableRecipient,
  otherPrestationAsked: otherPrestationAsked,
  deliveryImpossible: deliveryImpossible,
  wrongShippingAddress: wrongShippingAddress,
  recoveryRefusal: recoveryRefusal,
  missingPackets: missingPackets,
  humanize: humanize$9
};

var preOrdered = {
  id: "_a02ad307",
  defaultMessage: "Livraison pré-commandée"
};

var confirmed$1 = {
  id: "_21c435fc",
  defaultMessage: "Livraison confirmée"
};

var pickedUp = {
  id: "_5fc87e06",
  defaultMessage: "Retiré"
};

var delivered$1 = {
  id: "_fe5373c9",
  defaultMessage: "Commande livrée"
};

var pickupFailed = {
  id: "_7df1d2e0",
  defaultMessage: "Échec de retrait"
};

var deliveryFailed$1 = {
  id: "_34750d7d",
  defaultMessage: "Échec de livraison"
};

var deliveryReturnFailed = {
  id: "_57a23f85",
  defaultMessage: "Échec de retour colis"
};

var nonDeliverable$1 = {
  id: "_2e93222b",
  defaultMessage: "Non-livrable"
};

var deliveryReturned = {
  id: "_385d7f39",
  defaultMessage: "Commande retournée"
};

var deliveryCanceled = {
  id: "_16e24a90",
  defaultMessage: "Livraison annulée"
};

var preOrderedShort = {
  id: "_f2bf49d2",
  defaultMessage: "Pré-commandée"
};

var confirmedShort = {
  id: "_b02c794a",
  defaultMessage: "Confirmée"
};

var pickedUpShort = {
  id: "_dd569a2d",
  defaultMessage: "Retirée"
};

var deliveredShort = {
  id: "_8f9d883c",
  defaultMessage: "Livrée"
};

var pickupFailedShort = {
  id: "_c7858a9f",
  defaultMessage: "Échec retrait"
};

var deliveryFailedShort = {
  id: "_f9ae6e72",
  defaultMessage: "Échec"
};

var deliveryReturnFailedShort = {
  id: "_4e002097",
  defaultMessage: "Échec retour"
};

var deliveryReturnedShort = {
  id: "_7f61bb8c",
  defaultMessage: "Retournée"
};

var deliveryCanceledShort = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

var nonDeliverableShort = {
  id: "_2e93222b",
  defaultMessage: "Non-livrable"
};

function humanize$10(intl, status) {
  switch (status) {
    case "preOrdered" :
        return intl.formatMessage(preOrdered);
    case "confirmed" :
        return intl.formatMessage(confirmed$1);
    case "pickedUp" :
        return intl.formatMessage(pickedUp);
    case "delivered" :
        return intl.formatMessage(delivered$1);
    case "pickupFailed" :
        return intl.formatMessage(pickupFailed);
    case "deliveryFailed" :
        return intl.formatMessage(deliveryFailed$1);
    case "deliveryReturned" :
        return intl.formatMessage(deliveryReturned);
    case "deliveryCanceled" :
        return intl.formatMessage(deliveryCanceled);
    case "deliveryReturnFailed" :
        return intl.formatMessage(deliveryReturnFailed);
    case "nonDeliverable" :
        return intl.formatMessage(nonDeliverable$1);
    
  }
}

function humanizeShort(intl, status) {
  switch (status) {
    case "preOrdered" :
        return intl.formatMessage(preOrderedShort);
    case "confirmed" :
        return intl.formatMessage(confirmedShort);
    case "pickedUp" :
        return intl.formatMessage(pickedUpShort);
    case "delivered" :
        return intl.formatMessage(deliveredShort);
    case "pickupFailed" :
        return intl.formatMessage(pickupFailedShort);
    case "deliveryFailed" :
        return intl.formatMessage(deliveryFailedShort);
    case "deliveryReturned" :
        return intl.formatMessage(deliveryReturnedShort);
    case "deliveryCanceled" :
        return intl.formatMessage(deliveryCanceledShort);
    case "deliveryReturnFailed" :
        return intl.formatMessage(deliveryReturnFailedShort);
    case "nonDeliverable" :
        return intl.formatMessage(nonDeliverableShort);
    
  }
}

var StatusProvider = {
  preOrdered: preOrdered,
  confirmed: confirmed$1,
  pickedUp: pickedUp,
  delivered: delivered$1,
  pickupFailed: pickupFailed,
  deliveryFailed: deliveryFailed$1,
  deliveryReturnFailed: deliveryReturnFailed,
  nonDeliverable: nonDeliverable$1,
  deliveryReturned: deliveryReturned,
  deliveryCanceled: deliveryCanceled,
  preOrderedShort: preOrderedShort,
  confirmedShort: confirmedShort,
  pickedUpShort: pickedUpShort,
  deliveredShort: deliveredShort,
  pickupFailedShort: pickupFailedShort,
  deliveryFailedShort: deliveryFailedShort,
  deliveryReturnFailedShort: deliveryReturnFailedShort,
  deliveryReturnedShort: deliveryReturnedShort,
  deliveryCanceledShort: deliveryCanceledShort,
  nonDeliverableShort: nonDeliverableShort,
  humanize: humanize$10,
  humanizeShort: humanizeShort
};

var idle = {
  id: "_439149be",
  defaultMessage: "En attente"
};

var preparing = {
  id: "_848a71aa",
  defaultMessage: "En préparation"
};

var readyToPickUp = {
  id: "_ff8e21a7",
  defaultMessage: "Prêt à retirer"
};

var packageReturned$1 = {
  id: "_d9b12794",
  defaultMessage: "Retourné"
};

var pickedUp$1 = {
  id: "_5fc87e06",
  defaultMessage: "Retiré"
};

var canceled$1 = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

function humanize$11(intl, x) {
  switch (x) {
    case "idle" :
        return intl.formatMessage(idle);
    case "preparing" :
        return intl.formatMessage(preparing);
    case "ready_to_pick_up" :
        return intl.formatMessage(readyToPickUp);
    case "picked_up" :
        return intl.formatMessage(pickedUp$1);
    case "package_returned" :
        return intl.formatMessage(packageReturned$1);
    case "canceled" :
        return intl.formatMessage(canceled$1);
    
  }
}

var StatusOriginator = {
  idle: idle,
  preparing: preparing,
  readyToPickUp: readyToPickUp,
  packageReturned: packageReturned$1,
  pickedUp: pickedUp$1,
  canceled: canceled$1,
  humanize: humanize$11
};

var Store = {
  ModificationReason: ModificationReason,
  UserRole: UserRole,
  GetChangesHistoryField: GetChangesHistoryField,
  GetChangesHistoryType: GetChangesHistoryType,
  ModificationHistoryField: ModificationHistoryField,
  FullStatusProvider: FullStatusProvider,
  ModificationHistoryType: ModificationHistoryType,
  TransitionProblem: TransitionProblem,
  StatusProvider: StatusProvider,
  StatusOriginator: StatusOriginator
};

var unavailable = {
  id: "_1003c8ae",
  defaultMessage: "Indisponible"
};

var timeslotTooShort = {
  id: "_35e8314d",
  defaultMessage: "Créneau horaire trop court"
};

var distanceTooFar = {
  id: "_9151568d",
  defaultMessage: "Distance trop importante"
};

var price = {
  id: "_7dea345b",
  defaultMessage: "Prix insuffisant"
};

var insuitable = {
  id: "_8c94c2d4",
  defaultMessage: "Inadapté à mes services"
};

var other$2 = {
  id: "_fc0c78f1",
  defaultMessage: "Autre problème"
};

function humanize$12(intl, t) {
  switch (t) {
    case "unavailability" :
        return intl.formatMessage(unavailable);
    case "slot_too_short" :
        return intl.formatMessage(timeslotTooShort);
    case "distance" :
        return intl.formatMessage(distanceTooFar);
    case "price" :
        return intl.formatMessage(price);
    case "maladjusted" :
        return intl.formatMessage(insuitable);
    case "other" :
        return intl.formatMessage(other$2);
    
  }
}

var RefusalCause = {
  unavailable: unavailable,
  timeslotTooShort: timeslotTooShort,
  distanceTooFar: distanceTooFar,
  price: price,
  insuitable: insuitable,
  other: other$2,
  humanize: humanize$12
};

var inadapatedVehicle$1 = {
  id: "_fd60123e",
  defaultMessage: "Véhicule inadapté"
};

var handlingNotPossible$1 = {
  id: "_b4f8b6ad",
  defaultMessage: "Manutention impossible"
};

var unavailableOrder = {
  id: "_2ee73087",
  defaultMessage: "Commande indisponible"
};

var waiting$1 = {
  id: "_a3bcfe6e",
  defaultMessage: "Attente"
};

var closedPickupPoint$1 = {
  id: "_c088190f",
  defaultMessage: "Lieu de chargement inaccessible"
};

var packagingMissing$1 = {
  id: "_5ba19e9f",
  defaultMessage: "Colis manquant(s)"
};

var wrongPickupAddress$1 = {
  id: "_4b15ced8",
  defaultMessage: "Adresse de chargement invalide"
};

var insufficientPackaging$1 = {
  id: "_f2f2e500",
  defaultMessage: "Emballage(s) insuffisant(s)"
};

var nonAvailableRecipient$1 = {
  id: "_c3d028a9",
  defaultMessage: "Destinataire indisponible"
};

var damagedPackages$1 = {
  id: "_b98b6884",
  defaultMessage: "Colis endommagé(s)"
};

var nonCompliantPackages$1 = {
  id: "_5145ffec",
  defaultMessage: "Colis non conforme(s)"
};

var otherPrestationAsked$1 = {
  id: "_937cfdc3",
  defaultMessage: "Autre(s) prestation(s) demandée(s)"
};

var deliveryImpossible$1 = {
  id: "_1ec06c80",
  defaultMessage: "Livraison impossible"
};

var wrongShippingAddress$1 = {
  id: "_82ffe526",
  defaultMessage: "Adresse de livraison erronée"
};

var recoveryRefusal$1 = {
  id: "_21c60202",
  defaultMessage: "Refus de reprise"
};

var lackOfCapacity$1 = {
  id: "_c7bc1d12",
  defaultMessage: "Manque de capacité"
};

var truckProblem = {
  id: "_54fb32df",
  defaultMessage: "Accident / panne"
};

var costNotWorth = {
  id: "_06835e56",
  defaultMessage: "Prix d'achat non attractif"
};

var roadClosed = {
  id: "_ec1aa3bd",
  defaultMessage: "Route inaccessible / fermée"
};

var unservedZone = {
  id: "_1ff17955",
  defaultMessage: "Zone non desservie"
};

var missingPackets$1 = {
  id: "_60a3f16c",
  defaultMessage: "Colis manquant(s) ou incomplet(s)"
};

function humanize$13(intl, problem) {
  switch (problem) {
    case "inadapated_vehicle" :
        return intl.formatMessage(inadapatedVehicle$1);
    case "handling_not_possible" :
        return intl.formatMessage(handlingNotPossible$1);
    case "unavailable_order" :
        return intl.formatMessage(unavailableOrder);
    case "waiting" :
        return intl.formatMessage(waiting$1);
    case "closed_pickup_point" :
        return intl.formatMessage(closedPickupPoint$1);
    case "packaging_missing" :
        return intl.formatMessage(packagingMissing$1);
    case "wrong_pickup_address" :
        return intl.formatMessage(wrongPickupAddress$1);
    case "insufficient_packaging" :
        return intl.formatMessage(insufficientPackaging$1);
    case "non_available_recipient" :
        return intl.formatMessage(nonAvailableRecipient$1);
    case "damaged_packages" :
        return intl.formatMessage(damagedPackages$1);
    case "non_compliant_packages" :
        return intl.formatMessage(nonCompliantPackages$1);
    case "other_prestation_asked" :
        return intl.formatMessage(otherPrestationAsked$1);
    case "delivery_impossible" :
        return intl.formatMessage(deliveryImpossible$1);
    case "wrong_shipping_address" :
        return intl.formatMessage(wrongShippingAddress$1);
    case "recovery_refusal" :
        return intl.formatMessage(recoveryRefusal$1);
    case "lack_of_capacity" :
        return intl.formatMessage(lackOfCapacity$1);
    case "truck_problem" :
        return intl.formatMessage(truckProblem);
    case "cost_not_worth" :
        return intl.formatMessage(costNotWorth);
    case "road_closed" :
        return intl.formatMessage(roadClosed);
    case "unserved_zone" :
        return intl.formatMessage(unservedZone);
    case "missing_packets" :
        return intl.formatMessage(missingPackets$1);
    
  }
}

var Problem = {
  inadapatedVehicle: inadapatedVehicle$1,
  handlingNotPossible: handlingNotPossible$1,
  unavailableOrder: unavailableOrder,
  waiting: waiting$1,
  closedPickupPoint: closedPickupPoint$1,
  packagingMissing: packagingMissing$1,
  wrongPickupAddress: wrongPickupAddress$1,
  insufficientPackaging: insufficientPackaging$1,
  nonAvailableRecipient: nonAvailableRecipient$1,
  damagedPackages: damagedPackages$1,
  nonCompliantPackages: nonCompliantPackages$1,
  otherPrestationAsked: otherPrestationAsked$1,
  deliveryImpossible: deliveryImpossible$1,
  wrongShippingAddress: wrongShippingAddress$1,
  recoveryRefusal: recoveryRefusal$1,
  lackOfCapacity: lackOfCapacity$1,
  truckProblem: truckProblem,
  costNotWorth: costNotWorth,
  roadClosed: roadClosed,
  unservedZone: unservedZone,
  missingPackets: missingPackets$1,
  humanize: humanize$13
};

var courseRefused = {
  id: "_afe8480a",
  defaultMessage: "Course refusée"
};

var courseAccepted = {
  id: "_c6a8954e",
  defaultMessage: "Course acceptée"
};

var idle$1 = {
  id: "_439149be",
  defaultMessage: "En attente"
};

var arrivalAtPickupPoint$1 = {
  id: "_f8d31c96",
  defaultMessage: "Arrivée au point de retrait"
};

var arrivalAtShippingPoint$1 = {
  id: "_1c586fa5",
  defaultMessage: "Arrivée au point de livraison"
};

var packageWithdrawn$1 = {
  id: "_bf76bc07",
  defaultMessage: "Colis retiré"
};

var packageWithdrawalPbOk$1 = {
  id: "_ac71e7d9",
  defaultMessage: "Colis retiré avec incident"
};

var deliveryFailed$2 = {
  id: "_ce941951",
  defaultMessage: "Echec livraison"
};

var delivered$2 = {
  id: "_8f9d883c",
  defaultMessage: "Livrée"
};

var packageWithdrawalFailed$1 = {
  id: "_c63457a6",
  defaultMessage: "Échec du retrait de colis"
};

var deliveryPbOk$1 = {
  id: "_caf8e591",
  defaultMessage: "Colis livré avec incident"
};

var packageReturned$2 = {
  id: "_d9b12794",
  defaultMessage: "Retourné"
};

var packageReturnFailed$1 = {
  id: "_65111754",
  defaultMessage: "Échec retour colis"
};

var canceled$2 = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

var nonDeliverable$2 = {
  id: "_2e93222b",
  defaultMessage: "Non-livrable"
};

function humanize$14(intl, x) {
  var msg;
  switch (x) {
    case "idle" :
        msg = idle$1;
        break;
    case "course_refused" :
        msg = courseRefused;
        break;
    case "course_accepted" :
        msg = courseAccepted;
        break;
    case "arrival_at_pickup_point" :
        msg = arrivalAtPickupPoint$1;
        break;
    case "arrival_at_shipping_point" :
        msg = arrivalAtShippingPoint$1;
        break;
    case "package_withdrawn" :
        msg = packageWithdrawn$1;
        break;
    case "package_withdrawal_pb_ok" :
        msg = packageWithdrawalPbOk$1;
        break;
    case "delivery_failed" :
        msg = deliveryFailed$2;
        break;
    case "delivered" :
        msg = delivered$2;
        break;
    case "package_withdrawal_failed" :
        msg = packageWithdrawalFailed$1;
        break;
    case "delivery_pb_ok" :
        msg = deliveryPbOk$1;
        break;
    case "package_returned" :
        msg = packageReturned$2;
        break;
    case "package_return_failed" :
        msg = packageReturnFailed$1;
        break;
    case "canceled" :
        msg = canceled$2;
        break;
    case "non_deliverable" :
        msg = nonDeliverable$2;
        break;
    
  }
  return intl.formatMessage(msg);
}

var StatusProvider$1 = {
  courseRefused: courseRefused,
  courseAccepted: courseAccepted,
  idle: idle$1,
  arrivalAtPickupPoint: arrivalAtPickupPoint$1,
  arrivalAtShippingPoint: arrivalAtShippingPoint$1,
  packageWithdrawn: packageWithdrawn$1,
  packageWithdrawalPbOk: packageWithdrawalPbOk$1,
  deliveryFailed: deliveryFailed$2,
  delivered: delivered$2,
  packageWithdrawalFailed: packageWithdrawalFailed$1,
  deliveryPbOk: deliveryPbOk$1,
  packageReturned: packageReturned$2,
  packageReturnFailed: packageReturnFailed$1,
  canceled: canceled$2,
  nonDeliverable: nonDeliverable$2,
  humanize: humanize$14
};

var confirmed$2 = {
  id: "_b02c794a",
  defaultMessage: "Confirmée"
};

var arrivalAtPickupPoint$2 = {
  id: "_f8d31c96",
  defaultMessage: "Arrivée au point de retrait"
};

var arrivalAtShippingPoint$2 = {
  id: "_1c586fa5",
  defaultMessage: "Arrivée au point de livraison"
};

var packageWithdrawn$2 = {
  id: "_dd569a2d",
  defaultMessage: "Retirée"
};

var packageWithdrawalPbOk$2 = {
  id: "_0d9d1e6e",
  defaultMessage: "Retirée avec réserve(s)"
};

var deliveryFailed$3 = {
  id: "_ce941951",
  defaultMessage: "Echec livraison"
};

var delivered$3 = {
  id: "_8f9d883c",
  defaultMessage: "Livrée"
};

var packageWithdrawalFailed$2 = {
  id: "_f5ca61a0",
  defaultMessage: "Echec retrait"
};

var deliveryPbOk$2 = {
  id: "_3c018d3f",
  defaultMessage: "Livrée avec réserve(s)"
};

var packageReturned$3 = {
  id: "_7f61bb8c",
  defaultMessage: "Retournée"
};

var packageReturnFailed$2 = {
  id: "_cd4abc2d",
  defaultMessage: "Echec retour"
};

var canceled$3 = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

var nonDeliverable$3 = {
  id: "_2e93222b",
  defaultMessage: "Non-livrable"
};

function humanize$15(intl, x) {
  switch (x) {
    case "confirmed" :
        return intl.formatMessage(confirmed$2);
    case "arrival_at_pickup_point" :
        return intl.formatMessage(arrivalAtPickupPoint$2);
    case "arrival_at_shipping_point" :
        return intl.formatMessage(arrivalAtShippingPoint$2);
    case "package_withdrawn" :
        return intl.formatMessage(packageWithdrawn$2);
    case "package_withdrawal_pb_ok" :
        return intl.formatMessage(packageWithdrawalPbOk$2);
    case "delivery_failed" :
        return intl.formatMessage(deliveryFailed$3);
    case "delivered" :
        return intl.formatMessage(delivered$3);
    case "package_withdrawal_failed" :
        return intl.formatMessage(packageWithdrawalFailed$2);
    case "delivery_pb_ok" :
        return intl.formatMessage(deliveryPbOk$2);
    case "package_returned" :
        return intl.formatMessage(packageReturned$3);
    case "package_return_failed" :
        return intl.formatMessage(packageReturnFailed$2);
    case "non_deliverable" :
        return intl.formatMessage(nonDeliverable$3);
    case "canceled" :
        return intl.formatMessage(canceled$3);
    
  }
}

var FullStatusProvider$1 = {
  confirmed: confirmed$2,
  arrivalAtPickupPoint: arrivalAtPickupPoint$2,
  arrivalAtShippingPoint: arrivalAtShippingPoint$2,
  packageWithdrawn: packageWithdrawn$2,
  packageWithdrawalPbOk: packageWithdrawalPbOk$2,
  deliveryFailed: deliveryFailed$3,
  delivered: delivered$3,
  packageWithdrawalFailed: packageWithdrawalFailed$2,
  deliveryPbOk: deliveryPbOk$2,
  packageReturned: packageReturned$3,
  packageReturnFailed: packageReturnFailed$2,
  canceled: canceled$3,
  nonDeliverable: nonDeliverable$3,
  humanize: humanize$15
};

var heavy = {
  id: "_089a32de",
  defaultMessage: "Lourd"
};

var lightweight = {
  id: "_366bec58",
  defaultMessage: "Léger"
};

function humanize$16(intl, value) {
  if (value === "heavy") {
    return intl.formatMessage(heavy);
  } else {
    return intl.formatMessage(lightweight);
  }
}

var DeliveryFlowType = {
  heavy: heavy,
  lightweight: lightweight,
  humanize: humanize$16
};

var Transporter = {
  RefusalCause: RefusalCause,
  Problem: Problem,
  StatusProvider: StatusProvider$1,
  FullStatusProvider: FullStatusProvider$1,
  DeliveryFlowType: DeliveryFlowType
};

var V5 = {
  Store: Store,
  Transporter: Transporter
};

var other$3 = {
  id: "_fc0c78f1",
  defaultMessage: "Autre problème"
};

var noPackage$1 = {
  id: "_cb3bbdfc",
  defaultMessage: "Aucun colis à retirer"
};

var tooMuchPackages$1 = {
  id: "_54573fd0",
  defaultMessage: "Trop de colis"
};

var tooManyPackages$1 = {
  id: "_54573fd0",
  defaultMessage: "Trop de colis"
};

var missingPackages$1 = {
  id: "_cc9a02db",
  defaultMessage: "Colis manquants"
};

var tooHeavy$1 = {
  id: "_f5d7f470",
  defaultMessage: "Colis trop lourd"
};

var wrongDimensions$1 = {
  id: "_a3f00218",
  defaultMessage: "Mauvaise dimensions"
};

var pickupPointClosed$1 = {
  id: "_16b63caa",
  defaultMessage: "Point de retrait fermé"
};

var absentCustomer$1 = {
  id: "_2f11f441",
  defaultMessage: "Client absent"
};

var unreachableCustomer$1 = {
  id: "_5991d5b3",
  defaultMessage: "Client injoignable"
};

var wrongAddress$1 = {
  id: "_04fdfae1",
  defaultMessage: "Mauvaise adresse"
};

var deliveryRejection$1 = {
  id: "_b3fadabb",
  defaultMessage: "Refus de réception"
};

var wrongPackage$1 = {
  id: "_5606fc22",
  defaultMessage: "Mauvais colis"
};

var packageInformationsError$1 = {
  id: "_fc0c78f1",
  defaultMessage: "Autre problème"
};

var waiting$2 = {
  id: "_439149be",
  defaultMessage: "En attente"
};

var damagedPackages$2 = {
  id: "_361410f3",
  defaultMessage: "Colis endommagés"
};

var unavailableCommand$1 = {
  id: "_2ee73087",
  defaultMessage: "Commande indisponible"
};

var packagingMissing$2 = {
  id: "_f2f2e500",
  defaultMessage: "Emballage(s) insuffisant(s)"
};

var delay$1 = {
  id: "_884c60d4",
  defaultMessage: "Retard"
};

var unavailableRecipient$1 = {
  id: "_c3d028a9",
  defaultMessage: "Destinataire indisponible"
};

var nonCompliantPackages$2 = {
  id: "_5145ffec",
  defaultMessage: "Colis non conforme(s)"
};

var customerRefusal$1 = {
  id: "_9a01813f",
  defaultMessage: "Le client a refusé le colis"
};

var inadapatedVehicle$2 = {
  id: "_fd60123e",
  defaultMessage: "Véhicule inadapté"
};

var handlingNotPossible$2 = {
  id: "_b4f8b6ad",
  defaultMessage: "Manutention impossible"
};

var closedPickupPoint$2 = {
  id: "_c088190f",
  defaultMessage: "Lieu de chargement inaccessible"
};

var wrongPickupAddress$2 = {
  id: "_8684b10f",
  defaultMessage: "Mauvaise adresse de chargement"
};

var insufficientPackaging$2 = {
  id: "_f2f2e500",
  defaultMessage: "Emballage(s) insuffisant(s)"
};

var nonAvailableRecipient$2 = {
  id: "_c3d028a9",
  defaultMessage: "Destinataire indisponible"
};

var otherPrestationAsked$2 = {
  id: "_937cfdc3",
  defaultMessage: "Autre(s) prestation(s) demandée(s)"
};

var deliveryImpossible$2 = {
  id: "_1ec06c80",
  defaultMessage: "Livraison impossible"
};

var wrongShippingAddress$2 = {
  id: "_43092f29",
  defaultMessage: "Adresse erronée"
};

var recoveryRefusal$2 = {
  id: "_21c60202",
  defaultMessage: "Refus de reprise"
};

var missingPackets$2 = {
  id: "_5ba19e9f",
  defaultMessage: "Colis manquant(s)"
};

function humanize$17(intl, x) {
  var msg;
  switch (x) {
    case "no_package" :
        msg = noPackage$1;
        break;
    case "too_much_packages" :
        msg = tooMuchPackages$1;
        break;
    case "too_many_packages" :
        msg = tooManyPackages$1;
        break;
    case "missing_packages" :
        msg = missingPackages$1;
        break;
    case "too_heavy" :
        msg = tooHeavy$1;
        break;
    case "wrong_dimensions" :
        msg = wrongDimensions$1;
        break;
    case "pickup_point_closed" :
        msg = pickupPointClosed$1;
        break;
    case "absent_customer" :
        msg = absentCustomer$1;
        break;
    case "unreachable_customer" :
        msg = unreachableCustomer$1;
        break;
    case "wrong_address" :
        msg = wrongAddress$1;
        break;
    case "delivery_rejection" :
        msg = deliveryRejection$1;
        break;
    case "wrong_package" :
        msg = wrongPackage$1;
        break;
    case "package_informations_error" :
        msg = packageInformationsError$1;
        break;
    case "delay" :
        msg = delay$1;
        break;
    case "unavailable_recipient" :
        msg = unavailableRecipient$1;
        break;
    case "customer_refusal" :
        msg = customerRefusal$1;
        break;
    case "other" :
        msg = other$3;
        break;
    case "inadapated_vehicle" :
        msg = inadapatedVehicle$2;
        break;
    case "handling_not_possible" :
        msg = handlingNotPossible$2;
        break;
    case "unavailable_command" :
    case "unavailable_order" :
        msg = unavailableCommand$1;
        break;
    case "waiting" :
        msg = waiting$2;
        break;
    case "closed_pickup_point" :
        msg = closedPickupPoint$2;
        break;
    case "packaging_missing" :
        msg = packagingMissing$2;
        break;
    case "missing_packets" :
        msg = missingPackets$2;
        break;
    case "wrong_pickup_address" :
        msg = wrongPickupAddress$2;
        break;
    case "insufficient_packaging" :
        msg = insufficientPackaging$2;
        break;
    case "non_available_recipient" :
        msg = nonAvailableRecipient$2;
        break;
    case "damaged_packages" :
        msg = damagedPackages$2;
        break;
    case "non_compliant_packages" :
        msg = nonCompliantPackages$2;
        break;
    case "other_prestation_asked" :
        msg = otherPrestationAsked$2;
        break;
    case "delivery_impossible" :
        msg = deliveryImpossible$2;
        break;
    case "wrong_shipping_address" :
        msg = wrongShippingAddress$2;
        break;
    case "recovery_refusal" :
        msg = recoveryRefusal$2;
        break;
    
  }
  return intl.formatMessage(msg);
}

var TransitionProblem$1 = {
  other: other$3,
  noPackage: noPackage$1,
  tooMuchPackages: tooMuchPackages$1,
  tooManyPackages: tooManyPackages$1,
  missingPackages: missingPackages$1,
  tooHeavy: tooHeavy$1,
  wrongDimensions: wrongDimensions$1,
  pickupPointClosed: pickupPointClosed$1,
  absentCustomer: absentCustomer$1,
  unreachableCustomer: unreachableCustomer$1,
  wrongAddress: wrongAddress$1,
  deliveryRejection: deliveryRejection$1,
  wrongPackage: wrongPackage$1,
  packageInformationsError: packageInformationsError$1,
  waiting: waiting$2,
  damagedPackages: damagedPackages$2,
  unavailableCommand: unavailableCommand$1,
  packagingMissing: packagingMissing$2,
  delay: delay$1,
  unavailableRecipient: unavailableRecipient$1,
  nonCompliantPackages: nonCompliantPackages$2,
  customerRefusal: customerRefusal$1,
  inadapatedVehicle: inadapatedVehicle$2,
  handlingNotPossible: handlingNotPossible$2,
  closedPickupPoint: closedPickupPoint$2,
  wrongPickupAddress: wrongPickupAddress$2,
  insufficientPackaging: insufficientPackaging$2,
  nonAvailableRecipient: nonAvailableRecipient$2,
  otherPrestationAsked: otherPrestationAsked$2,
  deliveryImpossible: deliveryImpossible$2,
  wrongShippingAddress: wrongShippingAddress$2,
  recoveryRefusal: recoveryRefusal$2,
  missingPackets: missingPackets$2,
  humanize: humanize$17
};

var allMissing = {
  id: "_cbf06f88",
  defaultMessage: "Manquante"
};

var complete = {
  id: "_fe39fd72",
  defaultMessage: "Complète"
};

var incomplete = {
  id: "_fea74062",
  defaultMessage: "Incomplète"
};

var waitForUnits = {
  id: "_826b4246",
  defaultMessage: "En attente d'UM"
};

function humanize$18(intl, t) {
  var msg;
  switch (t) {
    case "allMissing" :
        msg = allMissing;
        break;
    case "complete" :
        msg = complete;
        break;
    case "incomplete" :
        msg = incomplete;
        break;
    case "waitingForUnits" :
        msg = waitForUnits;
        break;
    
  }
  return intl.formatMessage(msg);
}

var OrderState = {
  allMissing: allMissing,
  complete: complete,
  incomplete: incomplete,
  waitForUnits: waitForUnits,
  humanize: humanize$18
};

var toIntegrate = {
  id: "_c9fbc696",
  defaultMessage: "À intégrer"
};

var error = {
  id: "_fe7d2cb7",
  defaultMessage: "Erreur"
};

var toIgnore = {
  id: "_8fa9ee3b",
  defaultMessage: "A ignorer"
};

var ongoing = {
  id: "_14d640be",
  defaultMessage: "En cours d'intégration"
};

var confirmed$3 = {
  id: "_acb4d482",
  defaultMessage: "Confirmé"
};

var invalid = {
  id: "_66231fb8",
  defaultMessage: "Invalide"
};

var toReIntegrate = {
  id: "_6d106d1c",
  defaultMessage: "À réintégrer"
};

function humanize$19(intl, t) {
  var msg;
  switch (t) {
    case "toIntegrate" :
        msg = toIntegrate;
        break;
    case "error" :
        msg = error;
        break;
    case "toIgnore" :
        msg = toIgnore;
        break;
    case "ongoing" :
        msg = ongoing;
        break;
    case "confirmed" :
        msg = confirmed$3;
        break;
    case "invalid" :
        msg = invalid;
        break;
    case "toReIntegrate" :
        msg = toReIntegrate;
        break;
    
  }
  return intl.formatMessage(msg);
}

var IntegrationStatus = {
  toIntegrate: toIntegrate,
  error: error,
  toIgnore: toIgnore,
  ongoing: ongoing,
  confirmed: confirmed$3,
  invalid: invalid,
  toReIntegrate: toReIntegrate,
  humanize: humanize$19
};

var confirmed$4 = {
  id: "_b02c794a",
  defaultMessage: "Confirmée"
};

var canceled$4 = {
  id: "_07b6e0d8",
  defaultMessage: "Annulée"
};

function humanize$20(intl, t) {
  var msg;
  msg = t === "confirmed" ? confirmed$4 : canceled$4;
  return intl.formatMessage(msg);
}

var OrderGlobalState = {
  confirmed: confirmed$4,
  canceled: canceled$4,
  humanize: humanize$20
};

var missing = {
  id: "_cbf06f88",
  defaultMessage: "Manquante"
};

var received = {
  id: "_36fe3436",
  defaultMessage: "Reçue"
};

function humanize$21(intl, t) {
  var msg;
  msg = t === "missing" ? missing : received;
  return intl.formatMessage(msg);
}

var ParcelStatus = {
  missing: missing,
  received: received,
  humanize: humanize$21
};

var monday = {
  id: "_9fc912ee",
  defaultMessage: "Lundi"
};

var tuesday = {
  id: "_09d99cb1",
  defaultMessage: "Mardi"
};

var wednesday = {
  id: "_90cfad83",
  defaultMessage: "Mercredi"
};

var thursday = {
  id: "_337be526",
  defaultMessage: "Jeudi"
};

var friday = {
  id: "_8f8eb0df",
  defaultMessage: "Vendredi"
};

var saturday = {
  id: "_49a79a00",
  defaultMessage: "Samedi"
};

var sunday = {
  id: "_aaa4996e",
  defaultMessage: "Dimanche"
};

function humanize$22(intl, x) {
  var msg;
  switch (x) {
    case "monday" :
        msg = monday;
        break;
    case "tuesday" :
        msg = tuesday;
        break;
    case "wednesday" :
        msg = wednesday;
        break;
    case "thursday" :
        msg = thursday;
        break;
    case "friday" :
        msg = friday;
        break;
    case "saturday" :
        msg = saturday;
        break;
    case "sunday" :
        msg = sunday;
        break;
    
  }
  return intl.formatMessage(msg);
}

var DayOfWeek = {
  monday: monday,
  tuesday: tuesday,
  wednesday: wednesday,
  thursday: thursday,
  friday: friday,
  saturday: saturday,
  sunday: sunday,
  humanize: humanize$22
};

var today = {
  id: "_6a661ab6",
  defaultMessage: "Aujourd'hui"
};

function humanize$23(intl, x) {
  var msg;
  switch (x) {
    case "today" :
        msg = today;
        break;
    case "monday" :
        msg = monday;
        break;
    case "tuesday" :
        msg = tuesday;
        break;
    case "wednesday" :
        msg = wednesday;
        break;
    case "thursday" :
        msg = thursday;
        break;
    case "friday" :
        msg = friday;
        break;
    case "saturday" :
        msg = saturday;
        break;
    case "sunday" :
        msg = sunday;
        break;
    
  }
  return intl.formatMessage(msg);
}

var DeliveryDay = {
  today: today,
  humanize: humanize$23
};

var proposed = {
  id: "_0a2c2e00",
  defaultMessage: "Proposée"
};

var accepted = {
  id: "_b9a3f7e6",
  defaultMessage: "Acceptée"
};

var refused = {
  id: "_5f8a6aad",
  defaultMessage: "Refusée"
};

var terminated = {
  id: "_8b6014f5",
  defaultMessage: "Terminée"
};

var obsolete = {
  id: "_bbf6a331",
  defaultMessage: "Obsolète"
};

var unassigned = {
  id: "_ceb0cddd",
  defaultMessage: "Assignation Supprimée"
};

function humanize$24(intl, t) {
  var msg;
  switch (t) {
    case "proposed" :
        msg = proposed;
        break;
    case "accepted" :
        msg = accepted;
        break;
    case "refused" :
        msg = refused;
        break;
    case "terminated" :
        msg = terminated;
        break;
    case "obsolete" :
        msg = obsolete;
        break;
    case "unassigned" :
        msg = unassigned;
        break;
    
  }
  return intl.formatMessage(msg);
}

var ProposalState = {
  proposed: proposed,
  accepted: accepted,
  refused: refused,
  terminated: terminated,
  obsolete: obsolete,
  unassigned: unassigned,
  humanize: humanize$24
};

var shuttle = {
  id: "_53f389d2",
  defaultMessage: "Réceptionnée à la suite d’une traction"
};

var carrierReturn = {
  id: "_4561210f",
  defaultMessage: "Retournée au hub par un livreur"
};

var routePreparation = {
  id: "_d8697e02",
  defaultMessage: "Consultée en préparation de tournée"
};

var inventory = {
  id: "_179bc3c6",
  defaultMessage: "Scannée lors d’un inventaire"
};

var returnToOwner = {
  id: "_5b71892e",
  defaultMessage: "Renvoyée vers le donneur d'ordre"
};

var loading = {
  id: "_3927523a",
  defaultMessage: "Chargée dans le véhicule léger"
};

var damage = {
  id: "_9d333917",
  defaultMessage: "Avarie"
};

var thrown = {
  id: "_a5077839",
  defaultMessage: "Jeté à la benne"
};

var carrierPickup = {
  id: "_148baf83",
  defaultMessage: "Retrait livreur"
};

var carrierShipping = {
  id: "_56c37071",
  defaultMessage: "Livraison livreur"
};

var routeControl = {
  id: "_d9c0e7b1",
  defaultMessage: "Contrôle tournée"
};

var wrongRouteControl = {
  id: "_33e5af1c",
  defaultMessage: "Contrôlée dans une mauvaise tournée"
};

var receivedByOwner = {
  id: "_56bd511b",
  defaultMessage: "Reçu par le donneur d'ordre"
};

var lost = {
  id: "_eb60c5f0",
  defaultMessage: "Déclarée perdue"
};

var technicalCleaning = {
  id: "_c2588991",
  defaultMessage: "Nettoyage technique"
};

function humanize$25(intl, t) {
  var msg;
  switch (t) {
    case "shuttle" :
        msg = shuttle;
        break;
    case "routePreparation" :
        msg = routePreparation;
        break;
    case "inventory" :
        msg = inventory;
        break;
    case "loading" :
        msg = loading;
        break;
    case "carrierReturn" :
        msg = carrierReturn;
        break;
    case "damage" :
        msg = damage;
        break;
    case "thrown" :
        msg = thrown;
        break;
    case "carrierPickup" :
        msg = carrierPickup;
        break;
    case "carrierShipping" :
        msg = carrierShipping;
        break;
    case "returnToOwner" :
        msg = returnToOwner;
        break;
    case "routeControl" :
        msg = routeControl;
        break;
    case "wrongRouteControl" :
        msg = wrongRouteControl;
        break;
    case "receivedByOwner" :
        msg = receivedByOwner;
        break;
    case "lost" :
        msg = lost;
        break;
    case "technicalCleaning" :
        msg = technicalCleaning;
        break;
    
  }
  return intl.formatMessage(msg);
}

var ScanContext = {
  shuttle: shuttle,
  carrierReturn: carrierReturn,
  routePreparation: routePreparation,
  inventory: inventory,
  returnToOwner: returnToOwner,
  loading: loading,
  damage: damage,
  thrown: thrown,
  carrierPickup: carrierPickup,
  carrierShipping: carrierShipping,
  routeControl: routeControl,
  wrongRouteControl: wrongRouteControl,
  receivedByOwner: receivedByOwner,
  lost: lost,
  technicalCleaning: technicalCleaning,
  humanize: humanize$25
};

var created = {
  id: "_2b385dd7",
  defaultMessage: "Créé"
};

var onGoing = {
  id: "_7202b8ff",
  defaultMessage: "En cours"
};

var succeeded = {
  id: "_f3ca514e",
  defaultMessage: "Succès"
};

var failed = {
  id: "_ae7741ab",
  defaultMessage: "Echoué"
};

function humanize$26(intl, t) {
  var msg;
  switch (t) {
    case "created" :
        msg = created;
        break;
    case "onGoing" :
        msg = onGoing;
        break;
    case "succeeded" :
        msg = succeeded;
        break;
    case "failed" :
        msg = failed;
        break;
    
  }
  return intl.formatMessage(msg);
}

var JobStatus = {
  created: created,
  onGoing: onGoing,
  succeeded: succeeded,
  failed: failed,
  humanize: humanize$26
};

var createDeliveries = {
  id: "_4da6c9de",
  defaultMessage: "Création de livraison"
};

var sendOrderEdi = {
  id: "_f864b78b",
  defaultMessage: "Envoi de commandes EDI"
};

function humanize$27(intl, t) {
  var msg;
  msg = t === "createDeliveries" ? createDeliveries : sendOrderEdi;
  return intl.formatMessage(msg);
}

var JobType = {
  createDeliveries: createDeliveries,
  sendOrderEdi: sendOrderEdi,
  humanize: humanize$27
};

var electric = {
  id: "_c1c6ef84",
  defaultMessage: "Électrique"
};

var gaz = {
  id: "_062df587",
  defaultMessage: "Gaz"
};

var humanPowered = {
  id: "_f4e84153",
  defaultMessage: "Humaine"
};

function humanize$28(intl, t) {
  var msg;
  switch (t) {
    case "electric" :
        msg = electric;
        break;
    case "gaz" :
        msg = gaz;
        break;
    case "humanPowered" :
        msg = humanPowered;
        break;
    
  }
  return intl.formatMessage(msg);
}

var EnergyType = {
  electric: electric,
  gaz: gaz,
  humanPowered: humanPowered,
  humanize: humanize$28
};

var zoneNotCovered = {
  id: "_ea667f01",
  defaultMessage: "Le code postal de livraison/de prélèvement est dans un zone qui n’est pas couverte par nos équipes"
};

var totalWeightTooHeavy = {
  id: "_80080eeb",
  defaultMessage: "Le poids total de la commande dépasse notre limite de prise en charge."
};

var packetTooBig = {
  id: "_087b9922",
  defaultMessage: "La dimension d’un ou plusieurs colis dépasse notre limite de prise en charge."
};

var packageTooBig = {
  id: "_b5692733",
  defaultMessage: "Les dimensions de la commande sont trop grandes."
};

var volumeTooBig = {
  id: "_14a30244",
  defaultMessage: "Le volume de la commande est trop important."
};

var slotOutOfOpening = {
  id: "_02921cec",
  defaultMessage: "Le créneau de livraison se trouve sur un créneau non travaillé."
};

var tooHeavySinglePacket = {
  id: "_0291d337",
  defaultMessage: "Le poids d’un ou plusieurs colis de la commande dépasse notre limite de prise en charge par colis."
};

var noTransportCapacity = {
  id: "_9d8a7816",
  defaultMessage: "Pas d’équipe disponible pour une livraison sur ce créneau."
};

var distanceTooFar$1 = {
  id: "_76b81034",
  defaultMessage: "La distance de livraison de dépasse notre limite."
};

var storeNotConfigured = {
  id: "_19ad7658",
  defaultMessage: "Le magasin n'est pas configuré pour pouvoir commander des courses."
};

var unknownStore = {
  id: "_37d34c0c",
  defaultMessage: "Le magasin avec l’ID précisé n’existe pas"
};

var conditionsNotMatched = {
  id: "_127c6eb5",
  defaultMessage: "La livraison ne peut pas être gérée via la grille tarifaire."
};

var slotTooNarrow = {
  id: "_94b84a56",
  defaultMessage: "Nous ne livrons pas sur le créneau horaire demandé ou le délai de prévenance est trop court."
};

var slotInThePast = {
  id: "_327642b4",
  defaultMessage: "Le créneau de livraison demandé est dépassé."
};

var slotBeforeFirstAvailableSlot = {
  id: "_6c640ae3",
  defaultMessage: "Le délai de prévenance n’est pas respecté."
};

var unauthorizedRequiredSkills = {
  id: "_cff1d134",
  defaultMessage: "Pas de disponibilité pour le type de service demandé."
};

var cannotCalculateDistance = {
  id: "_1662a044",
  defaultMessage: "Impossible de calculer la distance de la livraison."
};

var technicalError = {
  id: "_a9e12272",
  defaultMessage: "Erreur technique"
};

var configSlotsAreTooShort = {
  id: "_f05554d0",
  defaultMessage: "Les crénaux de livraisons possibles sont plus petits que la taille de créneau configurée"
};

var noCommonSlotBetweenConfigAndOptions = {
  id: "_25d2a5ac",
  defaultMessage: "Il n'y a pas de créneau de livraison compatible à la fois avec les options de livraison et avec la grille de vente"
};

function humanize$29(intl, t) {
  if (t.TAG !== "ErrorCause") {
    return t._0;
  }
  switch (t._0) {
    case "ZONE_NOT_COVERED" :
        return intl.formatMessage(zoneNotCovered);
    case "TOTAL_WEIGHT_TOO_HEAVY" :
        return intl.formatMessage(totalWeightTooHeavy);
    case "PACKET_TOO_BIG" :
        return intl.formatMessage(packetTooBig);
    case "PACKAGE_TOO_BIG" :
        return intl.formatMessage(packageTooBig);
    case "VOLUME_TOO_BIG" :
        return intl.formatMessage(volumeTooBig);
    case "SLOT_OUT_OF_OPENING" :
        return intl.formatMessage(slotOutOfOpening);
    case "TOO_HEAVY_SINGLE_PACKET" :
        return intl.formatMessage(tooHeavySinglePacket);
    case "NO_TRANSPORT_CAPACITY" :
        return intl.formatMessage(noTransportCapacity);
    case "DISTANCE_TOO_FAR" :
        return intl.formatMessage(distanceTooFar$1);
    case "STORE_NOT_CONFIGURED" :
        return intl.formatMessage(storeNotConfigured);
    case "UNKNOWN_STORE" :
        return intl.formatMessage(unknownStore);
    case "CONDITIONS_NOT_MATCHED" :
        return intl.formatMessage(conditionsNotMatched);
    case "SLOT_TOO_NARROW" :
        return intl.formatMessage(slotTooNarrow);
    case "SLOT_IN_THE_PAST" :
        return intl.formatMessage(slotInThePast);
    case "SLOT_BEFORE_FIRST_AVAILABLE_SLOT" :
        return intl.formatMessage(slotBeforeFirstAvailableSlot);
    case "UNAUTHORIZED_REQUIRED_SKILLS" :
        return intl.formatMessage(unauthorizedRequiredSkills);
    case "CANNOT_CALCULATE_DISTANCE" :
        return intl.formatMessage(cannotCalculateDistance);
    case "TECHNICAL_ERROR" :
        return intl.formatMessage(technicalError);
    case "NO_COMMON_SLOT_BETWEEN_CONFIG_AND_OPTIONS" :
        return intl.formatMessage(noCommonSlotBetweenConfigAndOptions);
    case "CONFIG_SLOTS_ARE_TOO_SHORT" :
        return intl.formatMessage(configSlotsAreTooShort);
    
  }
}

var OrderErrorCause = {
  zoneNotCovered: zoneNotCovered,
  totalWeightTooHeavy: totalWeightTooHeavy,
  packetTooBig: packetTooBig,
  packageTooBig: packageTooBig,
  volumeTooBig: volumeTooBig,
  slotOutOfOpening: slotOutOfOpening,
  tooHeavySinglePacket: tooHeavySinglePacket,
  noTransportCapacity: noTransportCapacity,
  distanceTooFar: distanceTooFar$1,
  storeNotConfigured: storeNotConfigured,
  unknownStore: unknownStore,
  conditionsNotMatched: conditionsNotMatched,
  slotTooNarrow: slotTooNarrow,
  slotInThePast: slotInThePast,
  slotBeforeFirstAvailableSlot: slotBeforeFirstAvailableSlot,
  unauthorizedRequiredSkills: unauthorizedRequiredSkills,
  cannotCalculateDistance: cannotCalculateDistance,
  technicalError: technicalError,
  configSlotsAreTooShort: configSlotsAreTooShort,
  noCommonSlotBetweenConfigAndOptions: noCommonSlotBetweenConfigAndOptions,
  humanize: humanize$29
};

export {
  Lift ,
  HeftingOption ,
  V5 ,
  TransitionProblem$1 as TransitionProblem,
  OrderState ,
  IntegrationStatus ,
  OrderGlobalState ,
  ParcelStatus ,
  DayOfWeek ,
  DeliveryDay ,
  ProposalState ,
  ScanContext ,
  JobStatus ,
  JobType ,
  EnergyType ,
  OrderErrorCause ,
}
/* No side effect */
