

import * as Ky from "ky";
import * as Qs from "qs";
import * as Msw from "msw";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as React from "react";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as MockOverlay from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/mock/MockOverlay.bs.js";
import * as Browser from "msw/browser";
import * as Caml_splice_call from "../../../../node_modules/rescript/lib/es6/caml_splice_call.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Spinner from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Spinner.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";
import * as ApiComponents__ErrorPlaceholder from "../../src/components/ApiComponents__ErrorPlaceholder.bs.js";

var instance = Ky.default.create({
      prefixUrl: "https://fake.com"
    });

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.test)
                  ]]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "test"), null));
  if (test.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test: test._0
            }
          };
  }
  var e = test._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function rawResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test",
                    false,
                    Spice.stringToJson(v.test)
                  ]]));
}

function rawResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "test"), null));
  if (test.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test: test._0
            }
          };
  }
  var e = test._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test2",
                    false,
                    Spice.stringToJson(v.test2)
                  ]]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test2 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "test2"), null));
  if (test2.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test2: test2._0
            }
          };
  }
  var e = test2._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test2" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config(param) {
  var deliveryId = param.deliveryId;
  return {
          kyInstance: instance,
          path: "test/" + deliveryId,
          requestOptions: {
            method: "POST",
            json: body_encode(param.body),
            searchParams: Qs.stringify(body_encode(param.params)),
            timeout: 5000
          },
          key: [
            "Test",
            deliveryId
          ],
          mapCustomErrors: (function (error) {
              var match = error.response;
              var tmp;
              if (match !== undefined) {
                var match$1 = match.status;
                tmp = match$1 !== 401 ? (
                    match$1 !== 404 ? ({
                          TAG: "Unknown",
                          _0: error
                        }) : ({
                          TAG: "Custom",
                          _0: "Error1"
                        })
                  ) : ({
                      TAG: "Custom",
                      _0: "Error2"
                    });
              } else {
                tmp = {
                  TAG: "Unknown",
                  _0: error
                };
              }
              return $$Promise.resolved(tmp);
            }),
          mapRawResponse: (function (response) {
              return $$Promise.resolved(Curry._2(Toolkit__Primitives_Result.Result.mapOk, $$Request.decodeRawResponse(response, rawResponse_decode), (function (param) {
                                return {
                                        test2: param.test
                                      };
                              })));
            })
        };
}

var TestError = {
  body_encode: body_encode,
  body_decode: body_decode,
  rawResponse_encode: rawResponse_encode,
  rawResponse_decode: rawResponse_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  config: config
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.test)
                  ]]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "test"), null));
  if (test.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test: test._0
            }
          };
  }
  var e = test._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function rawResponse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test",
                    false,
                    Spice.stringToJson(v.test)
                  ]]));
}

function rawResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "test"), null));
  if (test.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test: test._0
            }
          };
  }
  var e = test._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var error = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
  if (error.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              error: error._0
            }
          };
  }
  var e = error._0;
  return {
          TAG: "Error",
          _0: {
            path: ".error" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "test2",
                    false,
                    Spice.stringToJson(v.test2)
                  ]]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var test2 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "test2"), null));
  if (test2.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              test2: test2._0
            }
          };
  }
  var e = test2._0;
  return {
          TAG: "Error",
          _0: {
            path: ".test2" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$1(param) {
  var deliveryId = param.deliveryId;
  return {
          kyInstance: instance,
          path: "test2/" + deliveryId,
          requestOptions: {
            method: "POST",
            json: body_encode$1(param.body),
            searchParams: Qs.stringify(body_encode$1(param.params)),
            timeout: 5000
          },
          key: [
            "Playground_API",
            "TestError2",
            deliveryId
          ],
          mapCustomErrors: (function (error) {
              var res = error.response;
              if (res !== undefined) {
                return $$Promise.map($$Request.decodeResponseError(res, errorPayload_decode), (function (res) {
                              if (res.TAG === "Ok" && res._0.error === "ERROR_CODE") {
                                return {
                                        TAG: "Custom",
                                        _0: "ExplicitError1"
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              } else {
                return $$Promise.resolved({
                            TAG: "Unknown",
                            _0: error
                          });
              }
            }),
          mapRawResponse: (function (response) {
              return $$Promise.resolved(Curry._2(Toolkit__Primitives_Result.Result.mapOk, $$Request.decodeRawResponse(response, rawResponse_decode$1), (function (param) {
                                return {
                                        test2: param.test
                                      };
                              })));
            })
        };
}

var TestError2 = {
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  rawResponse_encode: rawResponse_encode$1,
  rawResponse_decode: rawResponse_decode$1,
  errorPayload_decode: errorPayload_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  config: config$1
};

var handlers = [
  Msw.http.post("https://fake.com/test/:id*", (function (param) {
          return Msw.HttpResponse.json(rawResponse_encode({
                          test: "test2"
                        }));
        })),
  Msw.http.post("https://fake.com/test2/:id*", (function (param) {
          return Msw.HttpResponse.json({
                      error: "ERROR_CODE"
                    }, {
                      status: 404
                    });
        }))
];

var worker = Caml_splice_call.spliceApply(Browser.setupWorker, [handlers]);

function Playground_API$Fetcher(props) {
  var match = $$Request.useFetcher(undefined, {
        response_decode: response_decode,
        config: config
      }, {
        deliveryId: "",
        body: {
          test: new Date()
        },
        params: {
          test: new Date()
        }
      });
  var reload = match[2];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Fetcher:"
                    }),
                JsxRuntime.jsx("button", {
                      children: "refresh",
                      onClick: (function (param) {
                          Curry._1(reload, undefined);
                        })
                    }),
                JsxRuntime.jsx("p", {
                      children: match[0].test2
                    })
              ]
            });
}

var Fetcher = {
  make: Playground_API$Fetcher
};

function Playground_API$FetcherError(props) {
  var match = $$Request.useFetcher(undefined, {
        response_decode: response_decode$1,
        config: config$1
      }, {
        deliveryId: "",
        body: {
          test: new Date()
        },
        params: {
          test: new Date()
        }
      });
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "Fetcher2:"
                    }),
                JsxRuntime.jsx("p", {
                      children: match[0].test2
                    })
              ]
            });
}

var FetcherError = {
  make: Playground_API$FetcherError
};

function Playground_API$Manual(props) {
  var match = $$Request.useManualRequest({
        response_decode: response_decode,
        config: config
      });
  var trigger = match[1];
  var req = match[0];
  React.useEffect((function (param) {
          Curry._1(trigger, {
                deliveryId: "",
                body: {
                  test: new Date()
                },
                params: {
                  test: new Date()
                }
              });
        }), []);
  var tmp;
  if (typeof req !== "object") {
    tmp = req === "NotAsked" ? null : JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {});
  } else {
    var match$1 = req._0;
    tmp = match$1.TAG === "Ok" ? JsxRuntime.jsx("p", {
            children: match$1._0.test2
          }) : JsxRuntime.jsx("p", {
            children: "Oupsie",
            className: "text-danger-500"
          });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("h3", {
                      children: "useManualRequest"
                    }),
                tmp
              ]
            });
}

var Manual = {
  make: Playground_API$Manual
};

function Playground_API(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(MockOverlay.make, {
                    worker: worker,
                    workerOptions: import.meta.env.PROD ? ({
                          serviceWorker: {
                            url: "/mockServiceWorker.js"
                          }
                        }) : ({}),
                    className: "!absolute -top-20",
                    children: [
                      JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                            fallbackRender: (function (param) {
                                var error = param.error;
                                switch (error.TAG) {
                                  case "DecodeError" :
                                      return JsxRuntime.jsx(ApiComponents__ErrorPlaceholder.DecodeError.make, {
                                                  error: error._0
                                                });
                                  case "Unknown" :
                                      return JsxRuntime.jsx("p", {
                                                  children: "Veuillez activer le mock et recharger la page",
                                                  className: "text-lg font-semibold text-danger-600"
                                                });
                                  case "Custom" :
                                      switch (error._0) {
                                        case "Error1" :
                                            return "error1";
                                        case "Error2" :
                                            return "error2";
                                        case "ExplicitError1" :
                                            return "ExplicitError1";
                                        
                                      }
                                  
                                }
                              }),
                            children: JsxRuntime.jsxs(React.Suspense, {
                                  children: [
                                    JsxRuntime.jsx(Playground_API$Fetcher, {}),
                                    JsxRuntime.jsx(Playground_API$FetcherError, {})
                                  ],
                                  fallback: Caml_option.some(JsxRuntime.jsx(Toolkit__Ui_Spinner.make, {}))
                                })
                          }),
                      JsxRuntime.jsx(Playground_API$Manual, {})
                    ]
                  }),
              className: "relative"
            });
}

var make = Playground_API;

export {
  instance ,
  TestError ,
  TestError2 ,
  handlers ,
  worker ,
  Fetcher ,
  FetcherError ,
  Manual ,
  make ,
}
/* instance Not a pure module */
