

import * as React from "react";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as UnleashApi from "../UnleashApi.bs.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";

function ApiComponents__CommunicationBanner$Banner(props) {
  var match = $$Request.useFetcher(undefined, {
        response_decode: UnleashApi.CommunicationBanner.response_decode,
        config: UnleashApi.CommunicationBanner.config
      }, props.backOffice);
  var unleashFeature = match[0];
  var match$1 = unleashFeature.strategies;
  if (match$1.length !== 1) {
    return null;
  }
  var match$2 = match$1[0];
  if (unleashFeature.enabled) {
    return JsxRuntime.jsx("div", {
                children: match$2.parameters.text,
                className: "bg-warning-50 border-t border-b border-warning-500 text-danger-700 text-center px-4 py-2 -mt-px"
              });
  } else {
    return null;
  }
}

var Banner = {
  make: ApiComponents__CommunicationBanner$Banner
};

function ApiComponents__CommunicationBanner(props) {
  return JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
              fallbackRender: (function (param) {
                  return null;
                }),
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(ApiComponents__CommunicationBanner$Banner, {
                              backOffice: props.backOffice
                            })),
                    fallback: Caml_option.some(null)
                  })
            });
}

var make = ApiComponents__CommunicationBanner;

export {
  Banner ,
  make ,
}
/* react Not a pure module */
