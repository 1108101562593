

import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";

var baseUrl = "/users";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

function t_encode(v) {
  switch (v) {
    case "admin" :
        return "admin";
    case "tech-admin" :
        return "tech-admin";
    case "client" :
        return "client";
    case "store" :
        return "store";
    case "transporter" :
        return "transporter";
    case "carrier" :
        return "carrier";
    case "supplier" :
        return "supplier";
    case "hub-handler" :
        return "hub-handler";
    case "external-service" :
        return "external-service";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("tech-admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "tech-admin"
                };
        } else if ("client" === v) {
          return {
                  TAG: "Ok",
                  _0: "client"
                };
        } else if ("store" === v) {
          return {
                  TAG: "Ok",
                  _0: "store"
                };
        } else if ("transporter" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else if ("hub-handler" === v) {
          return {
                  TAG: "Ok",
                  _0: "hub-handler"
                };
        } else if ("external-service" === v) {
          return {
                  TAG: "Ok",
                  _0: "external-service"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function isAdmin(role) {
  switch (role) {
    case "admin" :
    case "tech-admin" :
        return true;
    default:
      return false;
  }
}

var Role = {
  t_encode: t_encode,
  t_decode: t_decode,
  isAdmin: isAdmin
};

function argument_encode(v) {
  return Curry._1(Identifiers.UserId.t_encode, v);
}

function argument_decode(v) {
  return Curry._1(Identifiers.UserId.t_decode, v);
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "role",
                    false,
                    t_encode(v.role)
                  ],
                  [
                    "transporterId",
                    false,
                    Spice.optionToJson(Identifiers.TransporterId.t_encode, v.transporterId)
                  ],
                  [
                    "carrierId",
                    false,
                    Spice.optionToJson(Identifiers.CarrierId.t_encode, v.carrierId)
                  ]
                ]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    var transporterId = Spice.optionFromJson(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
    if (transporterId.TAG === "Ok") {
      var carrierId = Spice.optionFromJson(Identifiers.CarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
      if (carrierId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  role: role._0,
                  transporterId: transporterId._0,
                  carrierId: carrierId._0
                }
              };
      }
      var e = carrierId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".carrierId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = transporterId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".transporterId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec(userId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, undefined, baseUrl + "/" + userId);
}

var Config = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var GetUser = {
  Role: Role,
  Config: Config,
  $$Request: $$Request
};

export {
  baseUrl ,
  config ,
  GetUser ,
}
/* Request Not a pure module */
