import React from "react";
import ReactDOM from "react-dom/client";
import "@colisweb/rescript-toolkit/src/ui/styles.css";
import "@colisweb/rescript-toolkit/src/ui/Toolkit__Ui_DatetimeInput.css";
import "./custom.css";
import App from "../lib/es6_global/playground/PlaygroundApp.bs";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Suspense fallback={null}>
    <App />
  </React.Suspense>
);
